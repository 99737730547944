export const tempLoginUserData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  country: "United Kingdom",
  role: "USER",
};

export const tempUserData = {
  id: '',
  email: '',
  role: 'USER',
}

export const tempFoundProjectData = {
  projectInfo: {},
  projectPipeline: {},
  projectBuilds: {},
  projectCommits: {},
};

export const tempBuildData = [
  {
    version: "1.0",
    datePublished: "2023-01-15",
    commit: "abc123",
    changes: "Link",
    loc: 1000,
    coverage: "85%",
    unitTests: 250,
    autoTests: 150,
    liveLink: "https://main--silhouette-portal-main.netlify.app",
  },
  {
    version: "1.1",
    datePublished: "2023-03-10",
    commit: "def456",
    changes: "Link",
    loc: 1100,
    coverage: "90%",
    unitTests: 260,
    autoTests: 160,
    liveLink: "https://main--silhouette-portal-main.netlify.app",
  },
];
