import React, { useContext, useRef, useState } from 'react';
// API
import client from '../../api/client';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import RoleSelect from '../../components/utils/RoleSelect';
// Context
import { UserContext } from '../../context/UserContext';
// Utils
import CountrySelect from '../utils/CountrySelect';
import { tempLoginUserData } from '../../utils/TempData';
// Icons
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
// Constants
import { REGISTER_USER_API_URL } from '../../utils/Constants';

function RegisterForm() {
  const { user } = useContext(UserContext);

  const [registerFormData, setRegisterFormData] = useState(tempLoginUserData);
  const [registerError, setRegisterError] = useState(false);
  const [registrationFormData, setRegistrationFormData] = useState({
    active: false,
    success: false,
  });

  const [passwordTooShort, setPasswordTooShort] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [confirmPasswordFieldType, setConfirmPasswordFieldType] =
    useState('password');

  const passwordMatch = useRef(false);

  const handleSubmitRegisterForm = (event) => {
    event.preventDefault();

    if (
      registerFormData.password !== registerFormData.confirmPassword &&
      registerFormData.password.length > 0
    ) {
      return;
    }

    setRegistrationFormData({
      ...registrationFormData,
      active: true,
    });

    client
      .post(REGISTER_USER_API_URL, registerFormData, true)
      .then((res) => {
        setRegistrationFormData({
          ...registrationFormData,
          active: false,
          success: true,
        });
      })

      .catch((err) => {
        setRegisterError(true);
        console.error('Unable to register new user', err);
        setRegistrationFormData({
          ...registrationFormData,
          active: false,
        });
      });
  };

  const handleChange = (event) => {
    setRegisterError(false);
    const { name, value } = event.target;

    let confirmPass;

    if (name === 'password' && value.length < 8) {
      setPasswordTooShort(true);
    }

    if (name === 'password' && value.length >= 8) {
      setPasswordTooShort(false);
    }

    if (name === 'confirmPassword') {
      confirmPass = value;
    }

    if (name === 'confirmPassword') {
      if (registerFormData.password !== confirmPass) {
        passwordMatch.current = true;
      }
    }

    if (registerFormData.password === confirmPass) {
      passwordMatch.current = false;
    }

    setRegisterFormData({
      ...registerFormData,
      [name]: value,
    });
  };

  const handleCountryChange = (event) => {
    setRegisterError(false);
    setRegistrationFormData({
      ...registrationFormData,
      active: false,
      success: false,
    });

    const { value } = event.target;

    setRegisterFormData({
      ...registerFormData,
      country: value,
    });
  };

  const handleRoleChange = (event) => {
    setRegisterError(false);
    setRegistrationFormData({
      ...registrationFormData,
      active: false,
      success: false,
    });

    const { value } = event.target;

    setRegisterFormData({
      ...registerFormData,
      country: value,
    });
  };

  // Show password characters
  const displayPassword = () => {
    setPasswordFieldType('text');
  };
  const hidePassword = () => {
    setPasswordFieldType('password');
  };

  // Show confirm password characters
  const displayConfirmPassword = () => {
    setConfirmPasswordFieldType('text');
  };
  const hideConfirmPassword = () => {
    setConfirmPasswordFieldType('password');
  };

  return (
    <form className='h-full px-4 pt-4' onSubmit={handleSubmitRegisterForm}>
      <div className='grid lg:grid-cols-2 gap-1'>
        <div className='mb-2'>
          <label className='dark:text-white'>First Name:</label>
          <input
            type='text'
            id='firstName'
            name='firstName'
            className='form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
            placeholder=''
            onChange={handleChange}
          />
        </div>
        <div className='mb-2'>
          <label className='dark:text-white'>Last Name:</label>
          <input
            type='text'
            id='lastName'
            name='lastName'
            className='form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
            onChange={handleChange}
          />
        </div>
      </div>
      <div className='mb-2'>
        <label className='dark:text-white'>Email:</label>
        <input
          type='email'
          id='email'
          name='email'
          className='form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
          onChange={handleChange}
          autoComplete='new-password'
        />
      </div>
      <div className='grid lg:grid-cols-2 gap-1'>
        <div className='mb-2'>
          <label className='dark:text-white'>Password:</label>
          <div className='relative'>
            <input
              type={passwordFieldType}
              id='password'
              name='password'
              autoComplete='new-password'
              className='form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
              placeholder='Password'
              onChange={handleChange}
            />
            {passwordFieldType === 'password' ? (
              <div
                onClick={displayPassword}
                className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
              >
                <AiFillEye size={25} />
              </div>
            ) : (
              <div
                onClick={hidePassword}
                className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
              >
                <AiFillEyeInvisible size={25} />
              </div>
            )}
          </div>
        </div>
        <div className='mb-2'>
          <label className='dark:text-white'>Confirm Password:</label>
          <div className='relative'>
            <input
              type={confirmPasswordFieldType}
              id='confirmPassword'
              name='confirmPassword'
              className='form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
              placeholder='Password'
              onChange={handleChange}
            />
            {confirmPasswordFieldType === 'password' ? (
              <div
                onClick={displayConfirmPassword}
                className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
              >
                <AiFillEye size={25} />
              </div>
            ) : (
              <div
                onClick={hideConfirmPassword}
                className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
              >
                <AiFillEyeInvisible size={25} />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Password considerations */}
      {passwordMatch.current === true && (
        <div className='text-center w-full mt-2'>
          <span className='text-red-600 font-semibold'>
            PASSWORDS DO NOT MATCH
          </span>
        </div>
      )}

      {passwordTooShort && (
        <div className='text-center w-full mt-2'>
          <span className='text-red-600 font-semibold'>PASSWORD TOO SHORT</span>
        </div>
      )}

      {/* Country selection drop down */}
      <div className='mb-2 grid'>
        <label className='pr-2 dark:text-white'>Country: </label>
        <CountrySelect user={user} handleCountryChange={handleCountryChange} />
      </div>

      {/* Role selection dropdown */}
      <div className='mb-6 grid'>
        <label className='pr-2 dark:text-white'>Role: </label>
        <RoleSelect user={user} handleRoleChange={handleRoleChange} />
      </div>

      {/* Submit button */}
      <div>
        <button
          type='submit'
          data-mdb-ripple='true'
          data-mdb-ripple-color='light'
          className='inline-block px-6 py-2.5 mb-6 no__highlights w-full bg-main-colour text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:brightness-105 hover:shadow-lg focus:bg-colour-dark focus:shadow-lg focus:outline-none focus:ring-0 active:bg-colour-dark active:shadow-lg transition duration-150 ease-in-out min-w-[220px]'
        >
          {!registrationFormData.active && !registrationFormData.success && (
            <span>Create New User</span>
          )}
          {registrationFormData.active && (
            <span className='flex items-center justify-center'>
              <LoadingSpinner width={'w-5'} height={'h-5'} />
            </span>
          )}
          {registrationFormData.success && <span>Success!</span>}
        </button>
      </div>

      {/* Error message */}
      {registerError && (
        <div className='text-center'>
          <span className='text-red-700 font-semibold'>REGISTER FAILED</span>
        </div>
      )}
    </form>
  );
}

export default RegisterForm;
