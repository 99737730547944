import jwt_decode from 'jwt-decode';
const tokenKey = process.env.REACT_APP_USER_TOKEN;

export default function LoggedInUser() {
  const loadedToken = localStorage.getItem(tokenKey);
  if (loadedToken === null || loadedToken === '') {
    return null;
  }

  const decoded = jwt_decode(loadedToken);

  return decoded;
}
