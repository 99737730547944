import React, { useContext, useEffect, useState } from 'react';
// Components
import Navbar from '../../components/nav/Navbar';
import UserAdminPanel from '../../components/admin/UserAdminPanel';
import OverviewAdminPanel from '../../components/admin/OverviewAdminPanel';
import ConfirmDeleteModal from '../../components/admin/ConfirmDeleteModal';
import PdfAdminPanel from '../../components/admin/PdfAdminPanel';
import LockstepAdminPanel from '../../components/admin/LockstepAdminPanel';
import ConfirmLockstepUpdateModal from '../../components/modals/ConfirmLockstepUpdateModal';
// Context
import { ToggleContext } from '../../context/ToggleContext';
import { UserContext } from '../../context/UserContext';
import { ModalContext } from '../../context/ModalContext';
// Constants
import { ADMIN_PAGE_URL, LOCKSTEP_ADMIN_TAB_PATH, OVERVIEW_ADMIN_TAB_PATH, PDF_ADMIN_TAB_PATH, USERS_ADMIN_TAB_PATH } from '../../utils/Constants';

function AdminPage() {
  const { setActiveNav, toggleDeleteUserModal } = useContext(ToggleContext);
  const { setQuickRegisterUserData } = useContext(UserContext);
  const { approvalModalIsOpen } = useContext(ModalContext);

  const [activeTab, setActiveTab] = useState(OVERVIEW_ADMIN_TAB_PATH);

  useEffect(() => {
    setActiveNav(ADMIN_PAGE_URL);
  }, []);

  // Selection buttons
  const selectOverviewTab = () => {
    setActiveTab(OVERVIEW_ADMIN_TAB_PATH);
  };

  const selectUsersTab = () => {
    setActiveTab(USERS_ADMIN_TAB_PATH);
  };

  const selectPDFTab = () => {
    setActiveTab(PDF_ADMIN_TAB_PATH);
  };

  const selectExtraTab = () => {
    setActiveTab(LOCKSTEP_ADMIN_TAB_PATH);
  };

  // Create new user - quick create form auto fills data
  const createNewUserFromRequest = (request) => {
    setActiveTab(USERS_ADMIN_TAB_PATH);
    setQuickRegisterUserData(request);
  };

  return (
    <div className='grid font-poppins min-h-screen lg:h-screen grid-rows-reg overflow-hidden lg:max-h-screen dark:bg-dark-main'>
      <Navbar />

      {/* Main content */}
      <main className='grid h-full w-full p-4'>
        <div className='grid grid-rows-reg h-full w-full'>
          <article>
            <h3 className='text-2xl dark:text-white'>Admin Panel</h3>
          </article>

          <section className='grid mt-4 grid-rows-reg relative px-4'>
            {/* Menu bar */}
            <div className='grid grid-cols-4 w-fit text-white'>
              <div
                onClick={selectOverviewTab}
                className={
                  activeTab === OVERVIEW_ADMIN_TAB_PATH
                    ? 'grid px-2 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg bg-colour-dark cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                    : 'grid px-2 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg  bg-main-colour  cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                }
              >
                <span className='text-white text-center'>Overview</span>
              </div>
              <div
                onClick={selectUsersTab}
                className={
                  activeTab === USERS_ADMIN_TAB_PATH
                    ? 'grid px-2 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg bg-colour-dark cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                    : 'grid px-2 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg  bg-main-colour  cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                }
              >
                <span className='text-white text-center'>Users</span>
              </div>
              <div
                onClick={selectPDFTab}
                className={
                  activeTab === 'pdf-tab'
                    ? 'grid px-2 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg bg-colour-dark cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                    : 'grid px-2 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg  bg-main-colour  cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                }
              >
                <span className='text-white text-center'>PDF</span>
              </div>
              <div
                onClick={selectExtraTab}
                className={
                  activeTab === LOCKSTEP_ADMIN_TAB_PATH
                    ? 'grid px-2 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg bg-colour-dark cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                    : 'grid px-2 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg  bg-main-colour  cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                }
              >
                <span className='text-white text-center'>Lockstep</span>
              </div>
            </div>

            {/* Main contents */}
            <section className='grid h-full w-full shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] dark:shadow-[rgba(255,_255,_255,_0.25)_0px_2px_5px] rounded py-2 px-4'>
              {/* Data sections */}
              {activeTab === OVERVIEW_ADMIN_TAB_PATH && (
                <OverviewAdminPanel
                  createNewUserFromRequest={createNewUserFromRequest}
                />
              )}
              {activeTab === USERS_ADMIN_TAB_PATH && <UserAdminPanel />}
              {activeTab === PDF_ADMIN_TAB_PATH && <PdfAdminPanel />}
              {activeTab === LOCKSTEP_ADMIN_TAB_PATH && <LockstepAdminPanel />}
            </section>

            {/* Delete user modal pop up */}
            {toggleDeleteUserModal && <ConfirmDeleteModal />}

            {/* Confirm update lockstep id */}
            {approvalModalIsOpen && <ConfirmLockstepUpdateModal />}
          </section>
        </div>
      </main>
    </div>
  );
}

export default AdminPage;
