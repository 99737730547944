import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Components
import Navbar from '../../components/nav/Navbar';
import SelectBuildOrSpecContainer from '../../components/projects/SelectBuildOrSpecContainer';
import BuildDataContainer from '../../components/projects/BuildDataContainer';
import SpecDataContainer from '../../components/projects/SpecDataContainer';
import ChangesContainer from '../../components/projects/ChangesContainer';
import SpecDocumentContainer from '../../components/projects/SpecDocumentContainer';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
// Context
import { ToggleContext } from '../../context/ToggleContext';
import { PdfContext } from '../../context/PdfContext';
// Constants 
import { BUILDS_TAB_PATH, CHANGES_TAB_PATH, PDF_TAB_PATH, PROJECTS_TAB_PATH, REPOS_PAGE_URL, SPEC_TAB_PATH } from '../../utils/Constants';

function ProjectsPage() {
  const {
    setActiveNav,
    pdfPopoutIsOpen,
    closePdfViewer,
    googleDriveFileIsDownloading
  } = useContext(ToggleContext);

  const { pdfUrl } = useContext(PdfContext);

  // Tab data
  const [activeTab, setActiveTab] = useState(PROJECTS_TAB_PATH);
  const [buildOrSpecSelected, setBuildOrSpecSelected] = useState(false);
  const [buildSpecTabString, setBuildSpecTabString] = useState(null);
  const [changesSelected, setChangesSelected] = useState(false);

  // PDF
  const [pdfProjectSpecSelected, setPdfProjectSpecSelected] = useState(null);

  let navigate = useNavigate();
  let location = useLocation();

  // Set active nav tab
  useEffect(() => {
    setActiveNav(REPOS_PAGE_URL);
  }, []);

  useEffect(() => {
    if (location.state) {
      setActiveTab(location.state.tabName);
      setBuildOrSpecSelected(true);
      setBuildSpecTabString(location.state.name);
    }
  }, [location.state]);

  // Select build or spec
  const selectBuildOrSpec = (selection) => {
    setActiveTab(selection.tabName);
    setBuildOrSpecSelected(true);
    setBuildSpecTabString(selection.name);
  };

  // View changes to repos
  const selectChangesForVerison = (item) => {
    setActiveTab(CHANGES_TAB_PATH);
    setChangesSelected(true);
  };

  // Pick a pdf spec
  const selectSpecToView = (item) => {
    setActiveTab(PDF_TAB_PATH);
    setPdfProjectSpecSelected(item.name);
  };

  // Return to start - reset all
  const returnToProjectsTab = () => {
    setActiveTab(PROJECTS_TAB_PATH);
    setBuildOrSpecSelected(false);
  };

  const returnToBuildOrSpecTab = () => {
    if (buildSpecTabString === 'Spec') {
      setPdfProjectSpecSelected(null);
      setActiveTab(SPEC_TAB_PATH);
    }

    if (buildSpecTabString === 'Builds') {
      setActiveTab(BUILDS_TAB_PATH);
      setChangesSelected(false);
    }
  };


  return (
    <div className='grid font-poppins h-screen grid-rows-reg overflow-hidden max-h-screen relative bg-white dark:bg-dark-main'>
      <Navbar />

      {/* Main content */}
      <main className='grid h-full w-full p-4 overflow-hidden'>
        <div className='grid h-full w-full overflow-hidden'>

          {/* Container */}
          <section className='grid relative grid-rows-reg px-4 py-2 mt-2 overflow-hidden'>

            {/* Next builds Buttons */}
            {activeTab === BUILDS_TAB_PATH && (
              <div className='grid grid-cols-2 w-fit px-2 gap-2 absolute right-2 top-3'>
              </div>
            )}

            {/* Selector tabs */}
            <section className='grid grid-flow-col lg:grid-cols-5 w-fit'>
              <div
                id={PROJECTS_TAB_PATH}
                onClick={returnToProjectsTab}
                className={
                  activeTab === PROJECTS_TAB_PATH
                    ? 'grid px-4 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg bg-colour-dark cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                    : 'grid px-4 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg  bg-main-colour  cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                }
              >
                <span className='text-white text-center'>Home</span>
              </div>


              {buildOrSpecSelected && (
                <>
                  <div
                    id={BUILDS_TAB_PATH}
                    onClick={returnToBuildOrSpecTab}
                    className={
                      activeTab === BUILDS_TAB_PATH || activeTab === SPEC_TAB_PATH
                        ? 'grid px-4 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg bg-colour-dark hover:bg-colour-light cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                        : 'grid px-4 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg bg-main-colour hover:bg-colour-light cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                    }
                  >
                    <span className='text-white text-center'>{buildSpecTabString}</span>
                  </div>

                  {activeTab === PDF_TAB_PATH && (
                    <div
                      id={PDF_TAB_PATH}
                      className={
                        activeTab === PDF_TAB_PATH
                          ? 'grid px-4 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] bg-colour-dark cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                          : 'grid px-4 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] bg-main-colour cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                      }
                    >
                      <span className='text-white text-center'>PDF</span>
                    </div>
                  )}

                  {changesSelected && (
                    <div
                      id={CHANGES_TAB_PATH}
                      className={
                        activeTab === CHANGES_TAB_PATH
                          ? 'grid px-4 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] bg-colour-dark cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                          : 'grid px-4 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] bg-main-colour cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                      }
                    >
                      <span className='text-white text-center'>Changes</span>
                    </div>
                  )}
                </>
              )}
            </section>

            {/* Data container */}
            <section className='grid h-full w-full shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] dark:shadow-[rgba(255,_255,_255,_0.25)_0px_2px_5px] rounded p-2 md:overflow-hidden'>
              
              {/* PROJECTS TAB - select build or spec */}
              {activeTab === PROJECTS_TAB_PATH && (
                <SelectBuildOrSpecContainer
                  selectBuildOrSpec={selectBuildOrSpec}
                />
              )}

              {/* BUILDS TAB */}
              {activeTab === BUILDS_TAB_PATH && (
                <BuildDataContainer
                  selectChangesForVerison={selectChangesForVerison}
                />
              )}

              {/*  SPEC TAB */}
              {activeTab === SPEC_TAB_PATH && (
                <SpecDataContainer selectSpecToView={selectSpecToView} />
              )}

              {/* CHANGES TAB */}
              {activeTab === CHANGES_TAB_PATH &&
                buildSpecTabString === 'Builds' && <ChangesContainer />}

              {/* PDF TAB */}
              {activeTab === PDF_TAB_PATH && buildSpecTabString === 'Spec' && (
                <SpecDocumentContainer
                  pdfProjectSpecSelected={pdfProjectSpecSelected}
                />
              )}
            </section>
          </section>
        </div>
      </main>

      {googleDriveFileIsDownloading && (
        <div className='grid h-full w-full absolute p-2 z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-center items-center'>
          <LoadingSpinner width={'w-32'} height={'h-32'} />
        </div>
      )}

      {pdfPopoutIsOpen && (
        <div className='grid h-full w-full absolute p-2'>
          <div className='h-full w-full grid relative'>
            <section className='w-full border-2 border-black border-solid rounded'>
              <object
                data={pdfUrl}
                width='100%'
                type='application/pdf'
                frameBorder='0'
                height='100%'
                className='resize-y'
                aria-label='pdf display'
              ></object>
            </section>
            <div className='absolute z-10 bottom-10 right-0 transform -translate-x-1/2'>
              <button
                onClick={closePdfViewer}
                className='text-white h-fit bg-main-colour shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] dark:text-black py-2 px-4 font-semibold text-xl rounded hover:brightness-105 scale-95'
              >
                Close Fullscreen
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectsPage;
