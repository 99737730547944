import React, { useState } from 'react';
// Api
import client from '../../api/client';
// Components
import LoadingSpinner from '../utils/LoadingSpinner';
// Constants
import { DELETE_NEW_USER_REQUEST_API_URL } from '../../utils/Constants';

function ConfirmDeleteRequestModal({
  requestToDelete,
  closeDeleteNewUserRequestModal,
  cancelDeleteRequest,
}) {
  const [deletedUserData, setDeletedUserData] = useState({
    active: false,
    success: false,
  });

  // Confirm delete and send request
  const confirmDeleteUser = () => {
    setDeletedUserData({
      ...deletedUserData,
      active: true,
    });

    client
      .delete(`${DELETE_NEW_USER_REQUEST_API_URL}/${requestToDelete.id}`)
      .then((res) => {
        console.log('res.data', res.data);
        setDeletedUserData({
          ...deletedUserData,
          active: false,
          success: true,
        });
      })

      .catch((err) => {
        console.error('Unable to delete user', err);
        setDeletedUserData({
          ...deletedUserData,
          active: false,
        });
      });
  };

  return (
    <article className='absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 grid outline outline-1 outline-black bg-white rounded-lg w-[350px] h-[200px]'>
      <section className='grid justify-center items-center text-center'>
        <section className='mt-2'>
          <div>
            <span>ARE YOU SURE?</span>
          </div>
          <div>
            <span>This cannot be undone</span>
          </div>
        </section>

        {/* Delete button */}
        <div>
          <button
            type='submit'
            data-mdb-ripple='true'
            data-mdb-ripple-color='light'
            onClick={confirmDeleteUser}
            className='inline-block px-6 py-2.5 no__highlights mb-2 w-full bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-800 hover:shadow-lg focus:bg-red-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-900 active:shadow-lg transition duration-150 ease-in-out'
          >
            {!deletedUserData.active && !deletedUserData.success && (
              <span>Confirm Deletion</span>
            )}
            {deletedUserData.active && (
              <span className='flex items-center justify-center'>
                <LoadingSpinner width={'w-5'} height={'h-5'} />
              </span>
            )}
            {deletedUserData.success && (
              <span>Success! User Request Deleted</span>
            )}
          </button>

          {/* Cancel delete */}
          <div>
            <button
              onClick={cancelDeleteRequest}
              className='inline-block no__highlights px-6 py-2.5 mb-6 w-full bg-main-colour text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:brightness-105 hover:shadow-lg focus:bg-main-colour focus:shadow-lg focus:outline-none focus:ring-0 active:bg-colour-dark active:shadow-lg transition duration-150 ease-in-out'
            >
              <span>Cancel</span>
            </button>
          </div>

          {/* Close modal */}
          <div>
            {deletedUserData.success && (
              <button
                onClick={closeDeleteNewUserRequestModal}
                className='inline-block no__highlights px-6 py-2.5 mb-6 w-full bg-main-colour text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:brightness-105 hover:shadow-lg focus:bg-main-colour focus:shadow-lg focus:outline-none focus:ring-0 active:bg-colour-dark active:shadow-lg transition duration-150 ease-in-out'
              >
                <span>Close</span>
              </button>
            )}
          </div>
        </div>
      </section>
    </article>
  );
}

export default ConfirmDeleteRequestModal;
