import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// Api
import client from '../../api/client';
// Context
import { UserContext } from '../../context/UserContext';
// Components
import LoadingSpinner from '../utils/LoadingSpinner';
// Icons
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

function LoginForm() {
  const { setUser } = useContext(UserContext);

  const [loginInProgress, setLoginInProgress] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: '',
    keepMeLoggedIn: false,
  });

  const [passwordFieldType, setPasswordFieldType] = useState('password');

  let navigate = useNavigate();

  // Navigate to home after login
  const homePage = () => {
    navigate('/home', { replace: true });
  };

  // Submit login request
  const handleLogin = (event) => {
    event.preventDefault();

    setLoginInProgress(true);

    client
      .post('/login', loginFormData, false)

      .then((res) => {
        localStorage.setItem(
          process.env.REACT_APP_USER_TOKEN,
          res.data.data.token
        );

        setLoginInProgress(false);
        setUser(res.data.data.existingUser);
      })
      .then(() => homePage())

      .catch((err) => {
        setLoginError(true);
        console.error('Unable to login', err);
      });
  };

  // Listen for form changes
  const handleChange = (event) => {
    setLoginError(false);
    const { name, value } = event.target;

    setLoginFormData({
      ...loginFormData,
      [name]: value,
    });
  };

  // Checkbox
  const handleCheckedKeepMeLoggedIn = (event) => {
    setLoginFormData({
      ...loginFormData,
      keepMeLoggedIn: true,
    });
  };

  // Show password characters
  const displayPassword = () => {
    setPasswordFieldType('text');
  };
  const hidePassword = () => {
    setPasswordFieldType('password');
  };

  return (
    <form className='space-y-4 md:space-y-6' onSubmit={handleLogin}>
      <div>
        <label
          htmlFor='email'
          className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
        >
          Your email
        </label>
        <input
          type='email'
          name='email'
          id='email'
          className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-600 dark:focus:border-blue-600'
          placeholder='name@email.com'
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label
          htmlFor='password'
          className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
        >
          Password
        </label>
        <div className='relative'>
          <input
            type={passwordFieldType}
            name='password'
            id='password'
            placeholder='••••••••'
            className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-600 dark:focus:border-blue-600'
            required
            onChange={handleChange}
          />
          {passwordFieldType === 'password' ? (
            <div
              onClick={displayPassword}
              className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
            >
              <AiFillEye size={25} />
            </div>
          ) : (
            <div
              onClick={hidePassword}
              className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
            >
              <AiFillEyeInvisible size={25} />
            </div>
          )}
        </div>
      </div>
      <div className='flex items-center justify-between'>
        <div className='flex items-start'>
          <div className='flex items-center h-5'>
            <input
              className='peer relative appearance-none shrink-0 w-4 h-4 border border-blue-400 border-solid rounded-sm mt-1 bg-white
              focus:outline-none focus:ring-offset-0 focus:ring-1 focus:ring-blue-100
              checked:bg-main-colour'
              type='checkbox'
              id='rememberMe'
              name='rememberMe'
              onChange={handleCheckedKeepMeLoggedIn}
            />
          </div>
          <div className='ml-3 text-sm'>
            <label
              htmlFor='remember'
              className='text-gray-500 dark:text-gray-300'
            >
              Remember me
            </label>
          </div>
        </div>
        <Link
          to='/password-recovery'
          className='text-sm font-medium text-blue-600 dark:text-main-colour hover:underline'
        >
          Forgot password?
        </Link>
      </div>

      {/* Submit */}
      <button
        type='submit'
        className='w-full text-white bg-main-colour no__highlights hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-main-colour dark:hover:bg-primary-700 dark:focus:ring-primary-800'
      >
        {loginInProgress ? (
          <div className='grid w-full justify-center items-center text-white text-3xl text-center'>
            <LoadingSpinner width={'w-6'} height={'w-6'} />
          </div>
        ) : (
          <div>
            <span>Sign in</span>
          </div>
        )}
      </button>
      {loginError && (
        <div className='text-center'>
          <span className='text-red-700 font-semibold'>LOGIN FAILED</span>
        </div>
      )}
      <p className='font-light text-gray-500 dark:text-gray-400'>
        Don’t have an account?{' '}
        <Link
          to='/request-access'
          className='font-medium text-blue-600 dark:text-main-colour hover:underline'
        >
          Request Access
        </Link>
      </p>
    </form>
  );
}

export default LoginForm;
