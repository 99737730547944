import React, { useContext, useEffect, useState } from 'react';
// Context
import { ToggleContext } from '../../context/ToggleContext';
// Component
import EmptyNavbar from '../../components/nav/EmptyNavbar';
import LoginForm from '../../components/forms/LoginForm';

function LoginPage() {
  const { setActiveNav } = useContext(ToggleContext);
  const [gapiInited, setGapiInited] = useState(false);

  useEffect(() => {
    setActiveNav('/login');
  }, []);
 
  return (
    <div className='h-screen overflow-hidden grid dark:bg-dark-main dark:text-gray-100'>
      <section className='grid h-full grid-rows-reg'>
        <EmptyNavbar />
        <main className='grid items-center justify-center'>
          <div className='grid justify-center items-center w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700'>
            <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
              <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white'>
                Sign in to your account 
              </h1>

              {/* Login form */}
              <LoginForm />
            </div>
          </div>
        </main>
      </section>
    </div>
  );
}

export default LoginPage;
