import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Components
import Navbar from '../../components/nav/Navbar';
// Context
import { ToggleContext } from '../../context/ToggleContext';
// Data
import { AvailableRepoOptions } from '../../utils/data/RepoDataArray';
// Images
import BannerImageLight from '../../assets/images/banners/Silhouette Portal Long.png';
import BannerImageDark from '../../assets/images/banners/SilhouettePortal_darkmode.png';

function HomePage() {
  const { setActiveNav } = useContext(ToggleContext);

  let navigate = useNavigate();

  useEffect(() => {
    setActiveNav('/');
  }, []);

  const selectPageToView = (selection) => {
    navigate(`/repos`, { state: selection });
  };

  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    const darkModeListener = (e) => {
      setIsDarkMode(e.matches);
    };

    const darkModeMediaQuery = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    darkModeMediaQuery.addEventListener('change', darkModeListener);

    return () => {
      darkModeMediaQuery.removeEventListener('change', darkModeListener);
    };
  }, []);

  return (
    <div className='grid font-poppins h-screen grid-rows-reg overflow-hidden max-h-screen dark:bg-dark-main'>
      <Navbar />
      {/* Main */}
      <main className='grid h-full p-4 lg:p-1 items-center justify-center'>
        <section className='mb-16'>
          <div className='pb-8'>
            <img
              src={isDarkMode ? BannerImageDark : BannerImageLight}
              alt='Silhouette Repos Logo'
              className='no_highlights p-10'
            />
          </div>

          {/* Go to Repos page */}
          <section className='grid text-center rounded bg-main-colour px-6 py-8'>
            <article className=''>
              <h1 className='text-xl'>Welcome to Silhouette Portal</h1>
              <div className='mt-8 grid md:grid-cols-2 gap-4'>
                {AvailableRepoOptions?.map((selection, index) => {
                  return (
                    <div key={index} className='grid items-center mx-auto'>
                      <button
                        id={selection.idName}
                        onClick={() => selectPageToView(selection)}
                        className='shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] no__highlights bg-white text-main-colour hover:brightness-95 active:scale-95 w-[260px] h-[50px] text-2xl py-1 px-4 rounded-lg'
                      >
                        {selection.buttonText}
                      </button>
                    </div>
                  );
                })}
              </div>
            </article>
          </section>
        </section>
      </main>
    </div>
  );
}

export default HomePage;
