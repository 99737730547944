import React, { useContext, useEffect, useRef, useState } from 'react';
// Components
import Navbar from '../../components/nav/Navbar';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
// Context
import { UserContext } from '../../context/UserContext';
import { ToggleContext } from '../../context/ToggleContext';
// Api
import client from '../../api/client';
// Icons
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

function AccountPage() {
  const { user } = useContext(UserContext);
  const { setActiveNav } = useContext(ToggleContext);

  const [emailInUseMessage, setEmailInUseMessage] = useState(false);
  const [updateEmailData, setUpdatedEmailData] = useState({ email: '' });
  const [updateEmailStatus, setUpdateEmailStatus] = useState({
    active: false,
    success: false,
  });

  const [updatePasswordData, setUpdatedPasswordData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [updatePasswordStatus, setUpdatePasswordStatus] = useState({
    active: false,
    success: false,
  });
  const [passwordTooShort, setPasswordTooShort] = useState(false);
  const passwordMatch = useRef(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [confirmPasswordFieldType, setConfirmPasswordFieldType] =
    useState('password');

  useEffect(() => {
    setActiveNav('/account');
  }, []);

  // Listen for email changes
  const handleEmailChange = (event) => {
    const { name, value } = event.target;
    setEmailInUseMessage(false);

    setUpdatedEmailData({
      ...updateEmailData,
      [name]: value,
    });
  };

  // Update user email
  const submitUpdateEmailForm = (event) => {
    event.preventDefault();

    setUpdateEmailStatus({
      ...updateEmailStatus,
      active: true,
    });



    client
      .patch(`/users/update-email/${user.id}`, updateEmailData, true)

      .then((res) => {
        console.log('res', res.data);
        setUpdateEmailStatus({
          ...updateEmailStatus,
          active: false,
          success: true,
        });
      })

      .catch((err) => {
        console.error('Unable to update email', err);
        setUpdateEmailStatus({
          ...updateEmailStatus,
          active: false,
        });
        if (err.response.data.message === 'Email in use') {
          setEmailInUseMessage(true);
        }
      });
  };

  // Listen for password changes
  const handlePasswordChange = (event) => {
    const { name, value } = event.target;

    let confirmPass;

    if (name === 'password' && value.length < 8) {
      setPasswordTooShort(true);
    }

    if (name === 'password' && value.length >= 8) {
      setPasswordTooShort(false);
    }

    if (name === 'confirmPassword') {
      confirmPass = value;
    }

    if (name === 'confirmPassword') {
      if (updatePasswordData.password !== confirmPass) {
        passwordMatch.current = true;
      }
    }

    if (updatePasswordData.password === confirmPass) {
      passwordMatch.current = false;
    }

    setUpdatedPasswordData({
      ...updatePasswordData,
      [name]: value,
    });
  };

  // Update password
  const submitUpdatePasswordForm = (event) => {
    event.preventDefault();

    if (
      updatePasswordData.password !== updatePasswordData.confirmPassword &&
      updatePasswordData.password.length > 0
    ) {
      return;
    }

    setUpdatePasswordStatus({
      ...updatePasswordStatus,
      active: true,
    });

    client
      .patch(`/users/update-password/${user.id}`, updatePasswordData, true)

      .then((res) => {
        console.log('res', res.data);
        setUpdatePasswordStatus({
          ...updatePasswordStatus,
          active: false,
          success: true,
        });
      })

      .catch((err) => {
        console.error('Unable to update password', err);
        setUpdatePasswordStatus({
          ...updatePasswordStatus,
          active: false,
        });
      });
  };

  // Show password characters
  const displayPassword = () => {
    setPasswordFieldType('text');
  };
  const hidePassword = () => {
    setPasswordFieldType('password');
  };

  // Show confirm password characters
  const displayConfirmPassword = () => {
    setConfirmPasswordFieldType('text');
  };
  const hideConfirmPassword = () => {
    setConfirmPasswordFieldType('password');
  };

  return (
    <div className='grid font-poppins h-screen max-h-screen grid-rows-reg overflow-hidden dark:bg-dark-main'>
      <Navbar />
      {/* Main content */}
      <main className='grid grid-rows-reg h-full w-full overflow-hidden'>
        <section>
          <div className='p-4 dark:text-white'>
            <h3 className='text-xl font-semibold'>Your Account</h3>
            <h4 className='font-semibold mb-2'>Update your info</h4>
          </div>
        </section>

        {/* Main */}
        <section className='grid h-full w-full'>
          {/* Update user form */}
          <section className='grid -mt-4 lg:-mt-0 lg:grid-cols-2 gap-4 overflow-hidden p-4'>

            {/* First section email change */}
            <article className='shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] dark:shadow-[rgba(255,_255,_255,_0.25)_0px_2px_5px] rounded-lg bg-gray-50 dark:bg-dark-main h-full'>
              <div className='border-b-[1px] border-solid border-black p-2 dark:text-white'>
                <h6>Change email address</h6>
              </div>
              <div className='px-4 py-4 dark:text-white'>
                <p className='text-sm'>
                  Current Email:{' '}
                  <span className='font-semibold'>{user.email}</span>
                </p>
              </div>

              {/* Change email form */}
              <form className='p-2 md:w-2/3 mx-auto'>
                <label
                  htmlFor='email'
                  className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                >
                  Your new email
                </label>
                <input
                  type='email'
                  name='email'
                  id='email'
                  className='bg-gray-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-600 dark:focus:border-blue-600'
                  placeholder='name@email.com'
                  onChange={handleEmailChange}
                  required
                />

                {/* Email considerations */}
                {emailInUseMessage && (
                  <div className='text-center mt-4'>
                    <p className='text-red-500 font-semibold'>
                      Email already in use
                    </p>
                  </div>
                )}

                {/* Submit */}
                <div className='mt-6'>
                  <button
                    type='submit'
                    data-mdb-ripple='true'
                    data-mdb-ripple-color='light'
                    onClick={submitUpdateEmailForm}
                    className='inline-block px-6 py-2.5 mb-6 no__highlights w-full bg-main-colour text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:brightness-105 hover:shadow-lg focus:bg-colour-dark focus:shadow-lg focus:outline-none focus:ring-0 active:bg-colour-dark active:shadow-lg transition duration-150 ease-in-out min-w-[220px]'
                  >
                    {!updateEmailStatus.active &&
                      !updateEmailStatus.success && <span>Update Email</span>}
                    {updateEmailStatus.active && (
                      <span className='flex items-center justify-center'>
                        <LoadingSpinner width={'w-5'} height={'h-5'} />
                      </span>
                    )}
                    {updateEmailStatus.success && <span>Success!</span>}
                  </button>
                </div>
              </form>
            </article>

            {/* Change password form */}
            <article className='shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] dark:shadow-[rgba(255,_255,_255,_0.25)_0px_2px_5px] rounded-lg bg-gray-50 dark:bg-dark-main'>
              <div className='border-b-[1px] border-solid border-black p-2 dark:text-white'>
                <h6>Change password</h6>
              </div>
              <div className='px-4 py-4 dark:text-white'>
                <p className='text-sm'>
                  Password must be <span className='font-semibold'>8</span> characters long minimum.
                </p>
              </div>
              <form className='grid gap-2 p-2 md:w-2/3 mx-auto'>
                <div>
                  <label
                    htmlFor='email'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Password
                  </label>
                  <div className='relative'>
                    <input
                      type={passwordFieldType}
                      name='password'
                      id='password'
                      autoComplete='new-password'
                      className='bg-gray-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-600 dark:focus:border-blue-600'
                      placeholder='••••••••'
                      onChange={handlePasswordChange}
                      required
                    />
                    {passwordFieldType === 'password' ? (
                      <div
                        onClick={displayPassword}
                        className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
                      >
                        <AiFillEye size={25} />
                      </div>
                    ) : (
                      <div
                        onClick={hidePassword}
                        className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
                      >
                        <AiFillEyeInvisible size={25} />
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor='email'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-2'
                  >
                    Confirm Password
                  </label>
                  <div className='relative'>
                    <input
                      type={confirmPasswordFieldType}
                      name='confirmPassword'
                      id='confirmPassword'
                      autoComplete='new-password'
                      className='bg-gray-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-600 dark:focus:border-blue-600'
                      placeholder='••••••••'
                      onChange={handlePasswordChange}
                      required
                    />
                    {confirmPasswordFieldType === 'password' ? (
                      <div
                        onClick={displayConfirmPassword}
                        className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
                      >
                        <AiFillEye size={25} />
                      </div>
                    ) : (
                      <div
                        onClick={hideConfirmPassword}
                        className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
                      >
                        <AiFillEyeInvisible size={25} />
                      </div>
                    )}
                  </div>
                </div>

                {/* Password considerations */}
                {passwordMatch.current === true && (
                  <div className='text-center w-full mt-2'>
                    <span className='text-red-600 font-semibold'>
                      PASSWORDS DO NOT MATCH
                    </span>
                  </div>
                )}

                {passwordTooShort && (
                  <div className='text-center w-full mt-2'>
                    <span className='text-red-600 font-semibold'>
                      PASSWORD TOO SHORT
                    </span>
                  </div>
                )}
                {/* Submit */}
                <div className='mt-6'>
                  <button
                    type='submit'
                    data-mdb-ripple='true'
                    data-mdb-ripple-color='light'
                    onClick={submitUpdatePasswordForm}
                    className='inline-block px-6 py-2.5 mb-6 no__highlights w-full bg-main-colour text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:brightness-105 hover:shadow-lg focus:bg-colour-dark focus:shadow-lg focus:outline-none focus:ring-0 active:bg-colour-dark active:shadow-lg transition duration-150 ease-in-out min-w-[220px]'
                  >
                    {!updateEmailStatus.active &&
                      !updateEmailStatus.success && (
                        <span>Update Password</span>
                      )}
                    {updateEmailStatus.active && (
                      <span className='flex items-center justify-center'>
                        <LoadingSpinner width={'w-5'} height={'h-5'} />
                      </span>
                    )}
                    {updateEmailStatus.success && <span>Success!</span>}
                  </button>
                </div>
              </form>
            </article>
          </section>
        </section>
      </main>
    </div>
  );
}

export default AccountPage;
