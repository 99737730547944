import React from "react";
import { Link } from "react-router-dom";
// Context
// Images
import LogoImage from "../../assets/images/logos/Silhouette Portal Small.png";

function EmptyNavbar() {
  return (
    <nav className="h-full relative z-30 grid grid-cols-reg bg-main-colour py-2 shadow-[rgba(13,_38,_76,_0.25)_0px_2px_10px]">
      <section className="grid items-center justify-center pl-4">
        <Link className="no__highlights" to="/">
          <img
            className="w-12 no__highlights h-12"
            src={LogoImage}
            alt="Logo"
          />
        </Link>
      </section>
    </nav>
  );
}

export default EmptyNavbar;
