import { Route, Routes, Navigate } from 'react-router-dom';
import { useContext } from 'react';
// Authorization
import {
  AuthenticateAdmin,
  AuthenticateDeveloper,
  AuthenticateUser,
} from './utils/AuthenticateUser';
// Context
import { UserContext } from './context/UserContext';
// Pages
import HomePage from './pages/home/HomePage';
import LoginPage from './users/login/LoginPage';
import Error404 from './pages/error/Error404';
import ProjectPage from './pages/projects/ProjectsPage';
import AdminPage from './pages/admin/AdminPage';
import PasswordRecoveryPage from './pages/account/PasswordRecoveryPage';
import DeveloperPage from './pages/developer/DeveloperPage';
import RequestAccessPage from './pages/account/RequestAccessPage';
import AccountPage from './pages/account/AccountPage';
import SalesPage from './pages/sales/SalesPage';
import ApiPage from './pages/api/ApiPage';
// Constants
import {
  ACCOUNT_PAGE_URL,
  ADMIN_PAGE_URL,
  API_PAGE_URL,
  DEVELOPER_PAGE_URL,
  HOME_PAGE_URL,
  LOGIN_PAGE_URL,
  PASSWORD_RECOVERY_PAGE_URL,
  REPOS_PAGE_URL,
  REQUEST_ACCESS_PAGE_URL,
  SALES_PAGE_URL,
} from './utils/Constants';

function App() {
  const { user } = useContext(UserContext);

  return (
    <Routes>
      {/* Prevent user from seeing contents without logging in */}
      {!user.id && (
        <Route path='/' element={<Navigate to={LOGIN_PAGE_URL} />} />
      )}
      {user && <Route path='/' element={<Navigate to={HOME_PAGE_URL} />} />}

      {/* User routes */}
      <Route path={LOGIN_PAGE_URL} index element={<LoginPage />} />

      <Route
        path={REQUEST_ACCESS_PAGE_URL}
        index
        element={<RequestAccessPage />}
      />

      {/* User routes protected */}
      <Route
        path={HOME_PAGE_URL}
        element={
          <AuthenticateUser>
            <HomePage />
          </AuthenticateUser>
        }
      />

      <Route
        path={ACCOUNT_PAGE_URL}
        element={
          <AuthenticateUser>
            <AccountPage />
          </AuthenticateUser>
        }
      />

      <Route
        path={PASSWORD_RECOVERY_PAGE_URL}
        element={<PasswordRecoveryPage />}
      />

      <Route
        path={REPOS_PAGE_URL}
        element={
          <AuthenticateUser>
            <ProjectPage />
          </AuthenticateUser>
        }
      />

      <Route
        path={SALES_PAGE_URL}
        element={
          <AuthenticateUser>
            <SalesPage />
          </AuthenticateUser>
        }
      />

      <Route
        path={API_PAGE_URL}
        element={
          <AuthenticateUser>
            <ApiPage />
          </AuthenticateUser>
        }
      />

      {/* Secure routes */}
      <Route
        path={ADMIN_PAGE_URL}
        element={
          <AuthenticateAdmin>
            <AdminPage />
          </AuthenticateAdmin>
        }
      />

      {/* Developer Links */}
      <Route
        path={DEVELOPER_PAGE_URL}
        element={
          <AuthenticateDeveloper>
            <DeveloperPage />
          </AuthenticateDeveloper>
        }
      />

      {/* All access */}
      <Route path='*' element={<Error404 />} />
    </Routes>
  );
}

export default App;
