import React from 'react';
// Data
import { AvailableRepoOptions } from '../../utils/data/RepoDataArray';

function SelectBuildOrSpecContainer({ selectBuildOrSpec }) {
  return (
    <section className='grid grid-rows-reg mt-8 justify-center'>
      <article className='mt-4 my-8'>
        <div>
          <p className='dark:text-white text-center text-xl'>
          Select an option
          </p>
        </div>
      </article>

      {/* List of repos */}
      <section className='grid h-fit gap-2'>
        {AvailableRepoOptions?.map((selection, index) => {
          return (
            <div key={index} className='grid items-center mx-auto'>
              <button
                id={selection.idName}
                onClick={() => selectBuildOrSpec(selection)}
                className='shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] no__highlights bg-main-colour text-white hover:bg-colour-dark active:scale-95 w-[260px] h-[50px] text-2xl py-1 px-4 rounded-lg'
              >
                {selection.buttonText}
              </button>
            </div>
          );
        })}
      </section>
    </section>
  );
}

export default SelectBuildOrSpecContainer;
