import React, { useContext, useEffect, useRef, useState } from 'react';
// Api
import client from '../../api/client';
// Components
import LoadingSpinner from '../utils/LoadingSpinner';
import UpdateUserCountrySelect from '../utils/UpdateUserCountrySelect';
import RoleSelect from '../utils/RoleSelect';
// Context
import { ToggleContext } from '../../context/ToggleContext';
import { UserContext } from '../../context/UserContext';
// Icons
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

function EditUserForm({ selectedUser, setSelectedUser }) {
  const { openDeleteUserModal } = useContext(ToggleContext);
  const { setUserToDelete } = useContext(UserContext);

  const [updatedUserForm, setUpdatedUserForm] = useState({});

  const [passwordTooShort, setPasswordTooShort] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [confirmPasswordFieldType, setConfirmPasswordFieldType] =
    useState('password');

  useEffect(() => {
    setUpdatedUserForm(selectedUser);
  }, [selectedUser]);
  
  console.log('selectedUser', selectedUser);

  const [updateError, setUpdateError] = useState(false);

  const passwordMatch = useRef(false);

  const [updateFormData, setUpdateFormData] = useState({
    active: false,
    success: false,
  });

  // Submit updated user data
  const updateUserData = (event) => {
    event.preventDefault();

    if (
      updatedUserForm.password !== updatedUserForm.confirmPassword &&
      updatedUserForm.password.length > 0
    ) {
      return;
    }

    setUpdateFormData({
      ...updateFormData,
      active: true,
    });

    client
      .patch(`/users/update-user/${selectedUser.id}`, updatedUserForm)
      .then((res) => {
        console.log('res', res.data);
        setUpdateFormData({
          ...updateFormData,
          active: false,
          success: true,
        });
        setSelectedUser(res.data.data.updatedUser);
      })

      .catch((err) => {
        console.error('Unable to update user', err);
        setUpdateFormData({
          ...updateFormData,
          active: false,
          success: false,
        });
        setUpdateError(true);
      });
  };

  // Listen for data changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUpdateError(false);
    setUpdateFormData({
      ...updateFormData,
      active: false,
      success: false,
    });

    if (name === 'password' && value.length < 8) {
      setPasswordTooShort(true);
    }

    if (name === 'password' && value.length >= 8) {
      setPasswordTooShort(false);
    }

    let confirmPass;

    if (name === 'confirmPassword') {
      confirmPass = value;
    }

    if (name === 'confirmPassword') {
      if (updatedUserForm.password !== confirmPass) {
        passwordMatch.current = true;
      }
    }

    if (updatedUserForm.password === confirmPass) {
      passwordMatch.current = false;
    }

    setUpdatedUserForm({
      ...updatedUserForm,
      [name]: value,
    });
  };

  // Listen for country change
  const handleCountryChange = (event) => {
    setUpdateError(false);
    setUpdateFormData({
      ...updateFormData,
      active: false,
      success: false,
    });

    const { value } = event.target;

    setUpdatedUserForm({
      ...updatedUserForm,
      country: value,
    });
  };

  // Listen for role change
  const handleRoleChange = (event) => {
    setUpdateError(false);
    setUpdateFormData({
      ...updateFormData,
      active: false,
      success: false,
    });

    const { value } = event.target;

    setUpdatedUserForm({
      ...updatedUserForm,
      role: value,
    });
  };

  // Delete user button
  const deleteUser = () => {
    setUserToDelete(selectedUser);
    openDeleteUserModal();
  };

  // Show password characters
  const displayPassword = () => {
    setPasswordFieldType('text');
  };
  const hidePassword = () => {
    setPasswordFieldType('password');
  };

  // Show confirm password characters
  const displayConfirmPassword = () => {
    setConfirmPasswordFieldType('text');
  };
  const hideConfirmPassword = () => {
    setConfirmPasswordFieldType('password');
  };

  return (
    <section className='grid h-fit px-4'>
      <form className='h-fit pt-4' onSubmit={updateUserData}>
        <div className='grid lg:grid-cols-2 gap-1'>
          <div className='mb-2'>
            <label className='dark:text-white'>First Name: </label>
            <input
              type='text'
              id='firstName'
              name='firstName'
              className='form-control dark:text-black block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
              placeholder={updatedUserForm.firstName}
              onChange={handleChange}
            />
          </div>
          <div className='mb-2'>
            <label className='dark:text-white'>Last Name:</label>
            <input
              type='text'
              id='lastName'
              name='lastName'
              placeholder={updatedUserForm.lastName}
              className='form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='mb-2'>
          <label className='dark:text-white'>Email:</label>
          <input
            type='email'
            id='email'
            name='email'
            placeholder={updatedUserForm.email}
            className='form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
            onChange={handleChange}
            autoComplete='new-password'
          />
        </div>
        <div className='grid lg:grid-cols-2 gap-1'>
          <div className='mb-2'>
            <label className='dark:text-white'>Password: </label>
            <div className='relative'>
              <input
                type={passwordFieldType}
                id='password'
                name='password'
                autoComplete='new-password'
                className='form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                placeholder='Password'
                onChange={handleChange}
              />
              {passwordFieldType === 'password' ? (
                <div
                  onClick={displayPassword}
                  className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
                >
                  <AiFillEye size={25} />
                </div>
              ) : (
                <div
                  onClick={hidePassword}
                  className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
                >
                  <AiFillEyeInvisible size={25} />
                </div>
              )}
            </div>
          </div>
          <div className='mb-2'>
            <label className='dark:text-white'>Confirm Password:</label>
            <div className='relative'>
              <input
                type={confirmPasswordFieldType}
                id='confirmPassword'
                name='confirmPassword'
                className='form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                placeholder='Password'
                onChange={handleChange}
              />
              {confirmPasswordFieldType === 'password' ? (
                <div
                  onClick={displayConfirmPassword}
                  className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
                >
                  <AiFillEye size={25} />
                </div>
              ) : (
                <div
                  onClick={hideConfirmPassword}
                  className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:scale-110 active:scale-95'
                >
                  <AiFillEyeInvisible size={25} />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Password considerations */}
        {passwordMatch.current === true && (
          <div className='text-center w-full mt-2'>
            <span className='text-red-600 font-semibold'>
              PASSWORDS DO NOT MATCH
            </span>
          </div>
        )}

        {passwordTooShort && (
          <div className='text-center w-full mt-2'>
            <span className='text-red-600 font-semibold'>
              PASSWORD TOO SHORT
            </span>
          </div>
        )}

        {/* Country select drop down */}
        <div className='mb-2 grid'>
          <label className='pr-2 dark:text-white'>Country: </label>
          <UpdateUserCountrySelect
            user={updatedUserForm}
            handleCountryChange={handleCountryChange}
          />
        </div>

        {/* Role selection drop down */}
        <div className='mb-6 grid'>
          <label className='pr-2 dark:text-white'>Role: </label>
          <RoleSelect handleRoleChange={handleRoleChange} />
        </div>

        {/* Submit button */}
        <div>
          <button
            type='submit'
            data-mdb-ripple='true'
            data-mdb-ripple-color='light'
            className='inline-block px-6 py-2.5 mb-2 no__highlights w-full bg-main-colour text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:brightness-105 hover:shadow-lg focus:bg-colour-dark focus:shadow-lg focus:outline-none focus:ring-0 active:bg-colour-dark active:shadow-lg transition duration-150 ease-in-out min-w-[220px]'
          >
            {!updateFormData.active && !updateFormData.success && (
              <span>Update User</span>
            )}
            {updateFormData.active && (
              <span className='flex items-center justify-center'>
                <LoadingSpinner width={'w-5'} height={'h-5'} />
              </span>
            )}
            {updateFormData.success && !updateFormData.active && (
              <span>Success!</span>
            )}
          </button>
        </div>

        {/* Error message */}
        {updateError && (
          <div className='text-center mb-4'>
            <span className='text-red-700 font-semibold'>
              UPDATING USER FAILED
            </span>
          </div>
        )}
      </form>

      {/* Delete user button */}
      <div>
        <button
          type='submit'
          data-mdb-ripple='true'
          data-mdb-ripple-color='light'
          onClick={deleteUser}
          className='inline-block px-6 py-2.5 no__highlights mb-6 w-full bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-800 hover:shadow-lg focus:bg-red-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-900 active:shadow-lg transition duration-150 ease-in-out'
        >
          <span>Delete User</span>
        </button>
      </div>
    </section>
  );
}

export default EditUserForm;
