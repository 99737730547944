import React, { useContext, useEffect } from 'react';
// Components
import Navbar from '../../components/nav/Navbar';
// Context
import { ToggleContext } from '../../context/ToggleContext';
// Constants
import { SALES_PAGE_URL } from '../../utils/Constants';

function SalesPage() {
  const { setActiveNav } = useContext(ToggleContext);

  useEffect(() => {
    setActiveNav(SALES_PAGE_URL);
  }, []);
  
  return (
    <div className='grid font-poppins h-screen grid-rows-reg overflow-hidden max-h-screen relative bg-white dark:bg-dark-main'>
      <Navbar />

      {/* Main content */}
      <main className='grid h-full w-full p-4 overflow-hidden'>SalesPage</main>
    </div>
  );
}

export default SalesPage;
