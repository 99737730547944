import React, { useContext, useEffect, useState } from 'react';
// Context
import { UserContext } from '../../context/UserContext';
// Api
import client from '../../api/client';
import NewUserRequest from './NewUserRequest';
// Icon
import { ImBin } from 'react-icons/im';
// Modals
import ConfirmDeleteRequestModal from './ConfirmDeleteRequestModal';
// Constants
import { NEW_USER_REQUESTS_API_URL } from '../../utils/Constants';

function OverviewAdminPanel({ createNewUserFromRequest }) {
  const { setSelectedUser } = useContext(UserContext);
  const [foundUserRequests, setFoundUserRequests] = useState([]);
  const [confirmDeleteRequest, setConfirmDeleteRequest] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState({});

  useEffect(() => {
    setSelectedUser({});

    client
      .get(NEW_USER_REQUESTS_API_URL)
      .then((res) => {
        console.log('res', res.data);
        setFoundUserRequests(res.data.data.userRequests);
      })
      .catch((err) => {
        console.error('Unable to get users', err);
      });
  }, [requestToDelete]);

  const deleteUserRequest = (request) => {
    setConfirmDeleteRequest(true);
    setRequestToDelete(request);
  };

  const closeDeleteNewUserRequestModal = () => {
    setConfirmDeleteRequest(false);
    setRequestToDelete({});
  };

  return (
    <section className='grid relative'>
      <div>
        <article className='p-2 dark:text-white'>
          <section>
            <div>
              <h3 className='text-xl font-semibold'>
                Silhouette Portal Overview
              </h3>
            </div>
            <div>
              <p>Manage users and portal data</p>
            </div>
          </section>
          <section className='grid pt-4 text-xs'>
            <div>
              <p>
                For more information on how to complete tasks on this website.{' '}
              </p>
              <p>
                Please consult the README.md file on our CodeBase or shared
                Google Drive documentation
              </p>
              <p>
                You can find a copy by following{' '}
                <a
                  href='https://code.silhouettesoftware.com/projects/silhouette-suite/repositories/portal/blob/main/README.md'
                  target='_blank'
                  rel='noreferrer'
                  title='Link to Codebase Readme File'
                  className='text-hyperlink hover:text-hyperlink-hover'
                >
                  this link
                </a>
              </p>
            </div>
          </section>

          <section className='grid mt-8'>
            <div>
              <h5 className='text-xl font-semibold underline underline-offset-4'>
                Users
              </h5>
            </div>
            <div className='mt-1 text-xs'>
              <p>
                To edit user data, including Email, Password, Access permissions
                or delete.{' '}
              </p>
              <p>Click on the 'Users' tab above on this page</p>
            </div>
          </section>

          <section className='grid mt-8'>
            <div>
              <h5 className='text-xl font-semibold underline underline-offset-4'>
                Specification
              </h5>
            </div>
            <div className='mt-1 text-xs'>
              <p>
                To update the PDF documents found on the Project Spec page click
                on the 'PDF' tab, you must have admin or developer access.
              </p>
              <p>
                PDFs are loaded from a shared Google Drive. You must upload the
                new file to Google Drive first.
              </p>
              <p>
                Then change the form to point to the new Google Drive file
                address
              </p>
            </div>
          </section>

          {/* New user requests */}
          {foundUserRequests.length > 0 && (
            <section className='mt-8 shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] dark:shadow-[rgba(255,_255,_255,_0.25)_0px_2px_5px] rounded-lg hidden lg:grid overflow-hidden'>
              <div className='border-b-[1px] border-black dark:border-white border-solid p-2'>
                <h6 className='font-semibold glowing_text no__highlights'>
                  NEW USER REQUESTS PENDING
                </h6>
              </div>
              <article className='grid grid-cols-foura pl-1 py-1 font-semibold'>
                <div>First Name</div>
                <div>Last Name</div>
                <div>Email</div>
                <div>Country</div>
                <div className='grid items-center px-2'>
                  <ImBin />
                </div>
              </article>
              <section className=''>
                {foundUserRequests.map((request, index) => {
                  return (
                    <NewUserRequest
                      key={index}
                      request={request}
                      createNewUserFromRequest={createNewUserFromRequest}
                      index={index}
                      deleteUserRequest={deleteUserRequest}
                    />
                  );
                })}
              </section>
            </section>
          )}
        </article>
      </div>

      {/* Delete user modal */}
      {confirmDeleteRequest && (
        <ConfirmDeleteRequestModal
          requestToDelete={requestToDelete}
          closeDeleteNewUserRequestModal={closeDeleteNewUserRequestModal}
        />
      )}
    </section>
  );
}

export default OverviewAdminPanel;
