import React, { useContext, useEffect, useState } from 'react';
// Api
import client from '../../api/client';
// Context
import { ToggleContext } from '../../context/ToggleContext';
import { PdfContext } from '../../context/PdfContext';
// Utils
import PdfVersionSelection from '../utils/PdfVersionSelection';
import axios from 'axios';
// Host url
const host = process.env.REACT_APP_API_URL;

function SpecDocumentContainer({ pdfProjectSpecSelected }) {
  const { openPdfViewer } = useContext(ToggleContext);
  const { pdfUrl, setPdfUrl, webPDFs, connectPDFs, desktopPDFs, mobilePDFs } =
    useContext(PdfContext);
    
  const [downloadParams, setDownloadParams] = useState('');

  // Mobile and non compatible browser detection
  const [browserCannotDisplayPDF, setBrowserCannotDisplayPDF] = useState(false);

  // Select the latest version of the projects pdf to display on load
  useEffect(() => {
    switch (pdfProjectSpecSelected) {
      case 'Connect':
        setPdfUrl(`${host}/pdf/get-pdf/connect/${connectPDFs[0]}`);
        setDownloadParams(connectPDFs[0]);
        break;
      case 'Desktop':
        setPdfUrl(`${host}/pdf/get-pdf/desktop/${desktopPDFs[0]}`);
        setDownloadParams(desktopPDFs[0]);
        break;
      case 'Mobile':
        setPdfUrl(`${host}/pdf/get-pdf/mobile/${mobilePDFs[0]}`);
        setDownloadParams(mobilePDFs[0]);
        break;
      case 'Web':
        setPdfUrl(`${host}/pdf/get-pdf/web/${webPDFs[0]}`);
        setDownloadParams(webPDFs[0]);
        break;
      default:
    }
  }, []);

  const downloadPDF = async () => {
    let pdfProjectSpecSelectedLowerCase = pdfProjectSpecSelected.toLowerCase()

    try {
      const response = await axios.get(
        `${host}/pdf/get-pdf/${pdfProjectSpecSelectedLowerCase}/${downloadParams}`,
        {
          responseType: 'blob', // Important: Set responseType to 'blob' for binary data
        }
      );

      // Create a Blob from the binary data
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${pdfProjectSpecSelected}.pdf`;
      link.click();
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const handlePdfChange = (event) => {
    const { value } = event.target;

    setDownloadParams(value);
    // Create a source URL for the PDF in the server
    if (value.includes('Connect')) {
      setPdfUrl(`${host}/pdf/pdf_uploads/connect/${value}.pdf`);
    }
    if (value.includes('Web')) {
      setPdfUrl(`${host}/pdf/pdf_uploads/web/${value}.pdf`);
    }
    if (value.includes('Desktop')) {
      setPdfUrl(`${host}/pdf/pdf_uploads/desktop/${value}.pdf`);
    }
    if (value.includes('Mobile')) {
      setPdfUrl(`${host}/pdf/pdf_uploads/mobile/${value}.pdf`);
    }
  };

  return (
    <section className='grid grid-rows-reg h-full'>
      <div className='text-center py-2 dark:text-white'>
        <h3>{pdfProjectSpecSelected} &#62; Spec</h3>
      </div>

      <article className='grid h-full'>
        <section className='grid grid-rows-reg p-2'>
          <section className='grid lg:grid-cols-rev'>
            <div className='dark:text-white'>
              <div className='p-2'>
                <h4>
                  <span className='font-semibold'>Version Information</span>
                </h4>
              </div>
            </div>

            {/* Buttons */}
            <section className='grid grid-cols-3 w-fit gap-2 h-fit'>
              {/* Select repo PDF */}
              <div className='grid'>
                <PdfVersionSelection
                  handlePdfChange={handlePdfChange}
                  pdfProjectSpecSelected={pdfProjectSpecSelected}
                />
              </div>

              {/* download button */}
              <div className='grid'>
                <button
                  onClick={downloadPDF}
                  className='text-white h-fit shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] bg-main-colour hover:brightness-105 active:scale-95 py-2 px-4 text-xs font-semibold rounded'
                >
                  Download PDF
                </button>
              </div>
              {/* View pdf full screen */}
              <div className='grid'>
                <button
                  onClick={openPdfViewer}
                  className='text-white h-fit shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] bg-main-colour hover:brightness-105 active:scale-95 py-2 px-4 text-xs font-semibold rounded'
                >
                  View Fullscreen
                </button>
              </div>
            </section>
          </section>

          {/* Check if browser can display */}
          {!browserCannotDisplayPDF ? (
            <div className='p-2 grid h-full'>
              <section className='w-full shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] rounded'>
                {pdfUrl && (
                  <object
                    data={pdfUrl}
                    width='100%'
                    type='application/pdf'
                    frameBorder='0'
                    height='100%'
                    className='resize-y'
                  >
                    <embed src='' width='100%' height='600px' />
                  </object>
                )}
              </section>
            </div>
          ) : (
            // Non conforming browser message
            <div className='text-center'>
              <p className='text-xl text-red-500 font-semibold'>
                BROWSER DOES NOT SUPPORT PDF
              </p>
            </div>
          )}
        </section>
      </article>
    </section>
  );
}

export default SpecDocumentContainer;
