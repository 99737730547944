import React, { useContext } from 'react';
// Context
import { ModalContext } from '../../context/ModalContext';
import client from '../../api/client';

function ConfirmLockstepUpdateModal() {
  const {
    setApprovalModalIsOpen,
    lockstepId,
    setLockstepId,
    newLockstepId,
    setNewLockstepId,
  } = useContext(ModalContext);

  const closeModal = () => {
    setApprovalModalIsOpen(false);
  };

  const confirmAndUpdate = () => {
    client
      .postWithPass(`/version/set-version`, newLockstepId)
      .then((res) => {
        console.log('res', res.data);
        setLockstepId({
          major: res.data.data.updatedVersion.major,
          minor: res.data.data.updatedVersion.minor,
          revision: res.data.data.updatedVersion.revision,
        });
      })
      .then(setApprovalModalIsOpen(false))

      .catch((err) => {
        console.error('Unable to update lockstep ID', err);
      });
  };

  return (
    <article className='absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 grid outline outline-1 outline-black bg-white rounded-lg w-[350px] h-[200px] shadow-lg'>
      <section className='grid justify-center items-center text-center'>
        <section className='mt-2'>
          <div>
            <span>ARE YOU SURE?</span>
          </div>
          <div>
            <span>This cannot be undone</span>
          </div>
        </section>

        <section className='grid grid-cols-2 gap-2'>
          <div>
            <button
              onClick={closeModal}
              className='bg-red-400 px-4 py-1 rounded hover:brightness-105 active:scale-95'
            >
              CLOSE
            </button>
          </div>
          <div>
            <button
              onClick={confirmAndUpdate}
              className='bg-main-colour px-4 py-1 rounded hover:brightness-105 active:scale-95'
            >
              CONFRIM
            </button>
          </div>
        </section>
      </section>
    </article>
  );
}

export default ConfirmLockstepUpdateModal;
