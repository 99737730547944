import React from 'react';
// Components
import EmptyNavbar from '../../components/nav/EmptyNavbar';
import PasswordRecoveryForm from '../../components/forms/PasswordRecoveryForm';

function PasswordRecoveryPage() {
  return (
    <div className='grid font-poppins h-screen grid-rows-reg overflow-hidden max-h-screen w-full'>
      <EmptyNavbar />

      {/* Main content */}
      <main className='grid w-full h-full justify-center items-center bg-white overflow-hidden p-2'>
        <article className='rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700 mx-auto p-4'>
          <div className='text-center mb-6'>
            <h2 className='text-xl font-semibold'>Request New Password</h2>
          </div>
          <PasswordRecoveryForm />
        </article>
      </main>
    </div>
  );
}

export default PasswordRecoveryPage;
