import React from 'react';

function ConfirmYamlUpdateModal({ closeModal, updateSwaggerUI }) {
  return (
    <article className='absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 grid outline outline-1 outline-black bg-white rounded-lg w-[350px] h-[200px] shadow-lg'>
      <section className='grid justify-center items-center text-center'>
        <section className='mt-2'>
          <div>
            <h6>ARE YOU SURE?</h6>
          </div>
          <div>
            <p>Check carefully before commiting</p>
          </div>
        </section>

        <section className='grid grid-cols-2 gap-2'>
          <div>
            <button
              onClick={closeModal}
              className='bg-red-400 px-4 py-1 rounded hover:brightness-105 active:scale-95'
            >
              CLOSE
            </button>
          </div>
          <div>
            <button
              onClick={updateSwaggerUI}
              className='bg-main-colour px-4 py-1 rounded hover:brightness-105 active:scale-95'
            >
              CONFRIM
            </button>
          </div>
        </section>
      </section>
    </article>
  );
}

export default ConfirmYamlUpdateModal;
