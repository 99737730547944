import React from 'react';
// Components
import EmptyNavbar from '../../components/nav/EmptyNavbar';
import RequestAccessForm from '../../components/forms/RequestAccessForm';

function RequestAccessPage() {
  return (
    <div className='h-screen overflow-hidden grid max-h-screen w-full bg-gray-50 dark:bg-black dark:text-gray-100'>
      <section className='grid h-full w-full grid-rows-reg'>
        <EmptyNavbar />
        {/* Main content */}
        <main className='bg-white main__bg grid items-center h-full w-full justify-center p-2'>
          <div className='bg-white rounded-lg shadow dark:border p-6 w-full'>
            <article className='mb-6 text-center'>
              <h2 className='text-3xl font-semibold'>Request Access</h2>
              <p className='leading-4 mt-2'>
                Enter your details and a admin member will create a <br /> new
                account for you and notify you by email.
              </p>
            </article>

            {/* New user request form */}
            <RequestAccessForm />
          </div>
        </main>
      </section>
    </div>
  );
}

export default RequestAccessPage;
