import React, { useContext, useEffect, useState } from 'react';
// Context
import { PdfContext } from '../../context/PdfContext';

function PdfVersionSelection({ handlePdfChange, pdfProjectSpecSelected }) {
  const { webPDFs, connectPDFs, desktopPDFs, mobilePDFs } =
    useContext(PdfContext);

  // List of options for dropdown menu
  const [displayTitles, setDisplayTitles] = useState([]);
  
console.log('DISPLAY TITLES', displayTitles);
  // Choose what project to source from
  useEffect(() => {
    switch (pdfProjectSpecSelected) {
      case 'Web':
        setDisplayTitles(webPDFs);
        break;
      case 'Connect':
        setDisplayTitles(connectPDFs);
        break;
      case 'Desktop':
        setDisplayTitles(desktopPDFs);
        break;
      case 'Mobile':
        setDisplayTitles(mobilePDFs);
        break;
      default:
    }
  }, [pdfProjectSpecSelected]);

  // Capitialize first letter for display titles
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // Remove .pdf from the end of file names
  const modifiedDisplayTitles = displayTitles
    .map((title) => capitalizeFirstLetter(title.slice(0, -4)))
    .reverse(); // Reverse the array

  return (
    <select
      id='pdf_select'
      name='pdf_select'
      onChange={handlePdfChange}
      className='rounded text-xs text-center px-4'
      >
      {modifiedDisplayTitles.map((title, index) => (
        <option key={index} value={title} className='text-left'>
          {index === 0 ? `${title} (Latest)` : title}
        </option>
      ))}
    </select>
  );
}

export default PdfVersionSelection;
