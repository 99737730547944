import React, { useContext, useEffect, useState } from 'react';
// Components
import LoadingSpinner from '../utils/LoadingSpinner';
// API
import client from '../../api/client';
// Forms
import EditUserForm from '../forms/EditUserForm';
import RegisterForm from '../forms/RegisterForm';
import QuickRegisterForm from '../forms/QuickRegisterForm';
// Context
import { UserContext } from '../../context/UserContext';
// Constants
import { ALL_USERS_API_URL } from '../../utils/Constants';

function UserAdminPanel() {
  const { selectedUser, setSelectedUser, quickRegisterUserData } =
    useContext(UserContext);
  const [allUsersFound, setAllUsersFound] = useState([]);

  useEffect(() => {
    if (selectedUser !== null) {
      client
        .get(ALL_USERS_API_URL)
        .then((res) => {
          setAllUsersFound(res.data.data.users);
        })
        .catch((err) => {
          console.error('Unable to get users', err);
        });
    }
  }, [selectedUser]);

  // Select user to edit
  const editSelectedUser = (user) => {
    setSelectedUser({});
    setSelectedUser(user);
  };

  return (
    <section className='grid h-full px-1 py-2'>
      <section className='grid lg:grid-cols-2 overflow-hidden h-full w-full gap-4 p-1'>
        <section className='grid grid-rows-reg h-full w-full overflow-hidden shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] dark:shadow-[rgba(255,_255,_255,_0.25)_0px_2px_5px] rounded-lg'>
          <div className='bg-gray-300 py-1 grid grid-flow-col justify-between'>
            <div>
              <span className='pl-1'>Users</span>
            </div>
            <div>
              <span className='pr-4'>Click on a user to edit</span>
            </div>
          </div>
          <section
            className={
              allUsersFound.length === 0
                ? 'grid items-center justify-center'
                : 'grid'
            }
          >
            {allUsersFound.length === 0 ? (
              <div className='grid w-full justify-center items-center text-white text-3xl text-center'>
                <LoadingSpinner width={'w-20'} height={'w-20'} />
              </div>
            ) : (
              <div className='grid h-full grid-rows-reg overflow-y-scroll w-full text-xs'>
                <div className='grid grid-cols-5 bg-gray-400 px-1'>
                  <p>First Name</p>
                  <p>Last Name</p>
                  <p>Role</p>
                  <p className='col-span-2'>Email</p>
                </div>
                <section>
                  {allUsersFound.map((user, index) => {
                    return (
                      <article
                        onClick={() => editSelectedUser(user)}
                        key={index}
                        className={
                          index % 2 === 0
                            ? 'bg-blue-200 h-fit py-1 px-1 cursor-pointer hover:brightness-105'
                            : 'bg-blue-300 h-fit py-1 px-1 cursor-pointer hover:brightness-105'
                        }
                      >
                        <div className='grid grid-cols-5'>
                          <p>{user.firstName}</p>
                          <p>{user.lastName}</p>
                          <p>{user.role}</p>
                          <p className='col-span-2'>{user.email}</p>
                        </div>
                      </article>
                    );
                  })}
                </section>
              </div>
            )}
          </section>
        </section>

        <section className='grid grid-rows-reg h-full w-full overflow-hidden shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] dark:shadow-[rgba(255,_255,_255,_0.25)_0px_2px_5px] rounded-lg'>
          <div className='bg-gray-300 h-fit py-1'>
            {selectedUser.id ? (
              <span className='pl-1'>Edit User</span>
            ) : (
              <span className='pl-1'>Register New User</span>
            )}
          </div>
          <section className='grid'>
            {selectedUser.id ? (
              <EditUserForm
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            ) : (
              <div>
                {quickRegisterUserData.id ? (
                  <QuickRegisterForm />
                ) : (
                  <RegisterForm />
                )}
              </div>
            )}
          </section>
        </section>
      </section>
    </section>
  );
}

export default UserAdminPanel;
