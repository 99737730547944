import React, { useState } from 'react';
// Api
import client from '../../api/client';
// Components
import LoadingSpinner from '../utils/LoadingSpinner';

function PasswordRecoveryForm() {
  const [loginError, setLoginError] = useState(false);
  const [passwordRequestForm, setPasswordRequestForm] = useState({
    email: '',
  });
  const [passwordRequestData, setPasswordRequestData] = useState({
    active: false,
    success: false,
  });

  // Submit password request
  const handleRequestNewPassword = (event) => {
    event.preventDefault();

    setPasswordRequestData({
      ...passwordRequestData,
      active: true,
    });

    client
      .patch(`/users/password/reset-password`, passwordRequestForm, false)
      .then((res) => {
        console.log('res', res.data);
        setPasswordRequestData({
          ...passwordRequestData,
          active: false,
          success: true,
        });
      })

      .catch((err) => {
        setLoginError(true);
        console.error('Unable to request password reset', err);
        setPasswordRequestData({
          ...passwordRequestData,
          active: false,
        });
      });
  };

  // Listen for data changes
  const handleChange = (event) => {
    setLoginError(false);
    const { name, value } = event.target;

    setPasswordRequestForm({
      ...passwordRequestForm,
      [name]: value,
    });
  };

  return (
    <form
      className='space-y-4 md:space-y-6'
      onSubmit={handleRequestNewPassword}
    >
      <div>
        <label
          htmlFor='email'
          className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
        >
          Your email
        </label>
        <input
          type='email'
          name='email'
          id='email'
          className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full min-w-[340px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-600 dark:focus:border-blue-600'
          placeholder='name@email.com'
          onChange={handleChange}
          required
        />
      </div>

      {/* Submit */}
      <button
        type='submit'
        className='w-full text-white no__highlights bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'
      >
        {!passwordRequestData.active && !passwordRequestData.success && (
          <span>Request Password</span>
        )}
        {passwordRequestData.active && (
          <span className='flex items-center justify-center'>
            <LoadingSpinner width={'w-5'} height={'h-5'} />
          </span>
        )}
        {passwordRequestData.success && <span>Success! Password Sent</span>}
      </button>

      {/* Error message */}
      {loginError && (
        <div className='text-center'>
          <span className='text-red-700 font-semibold'>REQUEST FAILED</span>{' '}
          <br />
          <span className='text-red-700 font-semibold'>Please try again</span>
        </div>
      )}
    </form>
  );
}

export default PasswordRecoveryForm;
