export const AvailableProjectsToView = [
  {
    id: 1,
    name: 'Connect',
    buttonText: 'Silhouette Connect',
    idName: 'silhouette_connect',
    projectID: 'xxxxx',
    devopsID: 'SilhouetteConnect',
  },
  {
    id: 2,
    name: 'Desktop',
    buttonText: 'Silhouette Creative',
    idName: 'silhouette_creative',
    projectID: 'xxxxx',
    devopsID: 'SilhouetteCreative',
  },
  {
    id: 3,
    name: 'Mobile',
    buttonText: 'Silhouette Go',
    idName: 'silhouette_go',
    projectID: '3bb64292-e743-46ee-aa94-0031863e0208',
    devopsID: 'SilhouetteGo',
  },
  {
    id: 4,
    name: 'Web',
    buttonText: 'Silhouette Web',
    idName: 'silhouette_web',
    projectID: 'e1ec9f6f-ef27-45f9-9f28-b8f1f2e79fd4',
    devopsID: 'SilhouetteWeb',
  },
];

export const AvailableRepoOptions = [
  {
    id: 1,
    name: 'Builds',
    buttonText: 'Builds',
    idName: 'builds-repo',
    tabName: 'builds-tab',
  },
  {
    id: 2,
    name: 'Spec',
    buttonText: 'Spec',
    idName: 'spec-repo',
    tabName: 'spec-tab',
  },
];
