import React, { useContext, useEffect, useState } from 'react';
// Api
import client from '../../api/client';
// Components
import ProjectSelect from '../utils/ProjectSelect';
import LoadingSpinner from '../utils/LoadingSpinner';
// Context
import { UserContext } from '../../context/UserContext';
// Constants
import {
  pdfFilePathDataMobile,
  pdfFilePathDataWeb,
  pdfFilePathDataDesktop,
  pdfFilePathDataConnect,
  PDF_UPLOAD_API_URL,
} from '../../utils/Constants';

function PdfAdminPanel() {
  const { setSelectedUser } = useContext(UserContext);
  const [selectedRepoForPDF, setSelectedRepoForPDF] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadingFileData, setUploadingFileData] = useState({
    active: false,
    success: false,
  });
  const [uploadFailure, setUploadFailure] = useState(false);

  useEffect(() => {
    setSelectedUser({});
  }, []);

  const onFileChange = (event) => {
    setUploadFailure(false);
    const file = event.target.files[0];

    let newFileName;
    switch (selectedRepoForPDF) {
      case 'connect':
        newFileName = pdfFilePathDataConnect;
        break;
      case 'desktop':
        newFileName = pdfFilePathDataDesktop;
        break;
      case 'mobile':
        newFileName = pdfFilePathDataMobile;
        break;
      case 'web':
        newFileName = pdfFilePathDataWeb;
        break;
      default:
    }

    // Rename file
    const newFile = new File([file], newFileName, {
      type: file.type,
      lastModified: file.lastModified,
    });

    setSelectedFile(newFile);
  };

  // Submit pdf to server
  const onFileUpload = () => {
    setUploadFailure(false);

    setUploadingFileData({
      ...uploadingFileData,
      active: true,
    });

    const formData = new FormData();

    formData.append('file', selectedFile);

    console.log("aaaaaaaaa", `${PDF_UPLOAD_API_URL}${selectedRepoForPDF}`);
    client
      .postPdf(`${PDF_UPLOAD_API_URL}${selectedRepoForPDF}`, formData, false)
      .then((res) => {
        console.log('res', res.data);
        setUploadingFileData({
          ...uploadingFileData,
          active: false,
          success: true,
        });
      })

      .catch((err) => {
        console.error('Unable to upload PDF', err);
        setUploadFailure(true);
        setUploadingFileData({
          ...uploadingFileData,
          active: false,
        });
      });
  };

  const handleProjectChange = (event) => {
    setUploadFailure(false);

    const { value } = event.target;
    setSelectedRepoForPDF(value);
  };

  return (
    <section className='p-2 w-full h-full grid overflow-hidden '>
      <article className='grid w-full h-full'>
        <div>
          <h4 className='text-xl font-semibold dark:text-white'>PDF Admin</h4>
        </div>

        {/* Text info */}
        <article className='my-4 w-full overflow-hidden dark:text-white text-xs leading-5'>
          <p className='w-full'>
            Each repo has a specifications PDF document. If this changes and you
            would like to update the displayed PDF.
          </p>
          <p>
            Please select which repo from the drop down menu you intend to
            update.
          </p>
          <p>
            Click on 'Choose File' button and selected the new version from your
            local machine
          </p>
          <p>
            Finally click upload. The new file will be stored in the server.
          </p>
          <p>
            <span className='font-semibold'>Only</span> PDF's can be uploaded.
            Please do not attempt to upload other files.
          </p>
          <p>
            The file names are changed upon upload to fit with the code design.
            You do not need to rename any files.
          </p>
          <p>
            The upload process can only be reversed manually by a developer.
          </p>
          <div className='mt-4 md:mt-1 p-1'>
            <p className='my-1'>Select A Project to update</p>
            
            <ProjectSelect handleProjectChange={handleProjectChange} />
          </div>
        </article>

        <div>
          <p className='dark:text-white '>This will upload a selected PDF</p>
        </div>

        <div>
          <div className='grid w-full h-full'>
            {/* File upload */}
            <div className='grid w-full'>
              <input
                type='file'
                className='w-full dark:text-white'
                onChange={onFileChange}
              />
            </div>

            {/* Upload button */}
            <button
              className='shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] text-white no__highlights px-2 py-1 rounded-lg w-full md:w-[250px] mb-2 mt-4 bg-main-colour hover:brightness-105 active:scale-95'
              onClick={onFileUpload}
            >
              {!uploadingFileData.active && !uploadingFileData.success && (
                <span>Upload!</span>
              )}
              {uploadingFileData.active && (
                <span className='flex items-center justify-center'>
                  <LoadingSpinner width={'w-6'} height={'h-6'} />
                </span>
              )}
              {uploadingFileData.success && (
                <span className='dark:text-white'>Success! PDF uploaded</span>
              )}
            </button>

            {/* Error message */}
            <div className='grid pl-10'>
              {uploadFailure && (
                <span className='text-red-500 font-semibold dark:text-white'>
                  FAILED TO UPLOAD PDF
                </span>
              )}
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}

export default PdfAdminPanel;
