import React from 'react';

function ProjectSelect({ handleProjectChange }) {
  return (
    <select
      id='project-select'
      name='project-select'
      onChange={handleProjectChange}
      className='outline outline-1 outline-black rounded px-1 py-1 dark:text-black w-full md:w-[300px]'
      required
    >
      <option className='text-center' defaultValue='null'>
        - - - - - -
      </option>
      <option value='connect'>Connect</option>
      <option value='desktop'>Desktop</option>
      <option value='mobile'>Mobile</option>
      <option value='web'>Web</option>
    </select>
  );
}

export default ProjectSelect;
