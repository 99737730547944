export const devopsPipelineBuilds = 'Silhouette%20Suite';
export const silhouetteSuiteProjectID = 'd8c71310-2a98-4ad2-9121-caafe8bf03df';

// PDF data
export const pdfFilePathDataMobile = 'SilhouetteMobile.pdf';
export const pdfFilePathDataWeb = 'SilhouetteWeb.pdf';
export const pdfFilePathDataDesktop = 'SilhouetteDesktop.pdf';
export const pdfFilePathDataConnect = 'SilhouetteConnect.pdf';

// Paths
export const LOGIN_PAGE_URL = '/login';
export const REQUEST_ACCESS_PAGE_URL = '/request-access';
export const HOME_PAGE_URL = '/home';
export const ACCOUNT_PAGE_URL = '/account';
export const PASSWORD_RECOVERY_PAGE_URL = '/password-recovery';
export const REPOS_PAGE_URL = '/repos';
export const SALES_PAGE_URL = '/sales';
export const ADMIN_PAGE_URL = '/admin';
export const DEVELOPER_PAGE_URL = '/developer';
export const API_PAGE_URL = '/api';

// Active tabs
export const CHANGES_TAB_PATH = 'changes-tab';
export const PDF_TAB_PATH = 'pdf-tab';
export const PROJECTS_TAB_PATH = 'projects';
export const SPEC_TAB_PATH = 'spec-tab';
export const BUILDS_TAB_PATH = 'builds-tab';
// Admin tabs
export const OVERVIEW_ADMIN_TAB_PATH = 'overview-tab';
export const USERS_ADMIN_TAB_PATH = 'users-tab';
export const PDF_ADMIN_TAB_PATH = 'pdf-tab';
export const LOCKSTEP_ADMIN_TAB_PATH = 'lockstep-tab';
// Developer tabs
export const EVENTS_DEV_TAB_PATH = 'events-tab';
// API URLS
export const PDF_UPLOAD_API_URL = '/pdf/upload-pdf/'; // :fileDestination
export const ALL_USERS_API_URL = '/users/get-all-users/';
export const ALL_BUILD_API_URL = `/projects/silhouette-suite/get-all-builds`;
export const REGISTER_USER_API_URL = '/users/register';
export const NEW_USER_REQUESTS_API_URL = '/users/new-users/all-user-requests';
export const DELETE_NEW_USER_REQUEST_API_URL = '/new-users/delete-user-request';
export const ALL_EVENTS_API_URL = '/events/get-all-events';
export const GET_API_DOC_API_URL = '/api/get-api-diagram';
export const UPDATE_API_DOC_API_URL = '/api/update-api-diagram';

// Roles
export const ADMIN_ROLE = 'ADMIN';
export const DEVELOPER_ROLE = 'DEVELOPER';
export const USER_ROLE = 'USER';

// Google
export const GOOGLE_API_URL = 'https://apis.google.com/js/api.js';
export const GOOGLE_GSI_URL = 'https://accounts.google.com/gsi/client';
export const GOOGLE_FOLDER_ID = "'1ddcQN6lQpGpQ6y4o7h2r1vRamdEzLrEv'";

