import React, { useContext, useEffect } from "react";
// Components
import Navbar from "../../components/nav/Navbar";
import EmptyNavbar from "../../components/nav/EmptyNavbar";
// Context
import { ToggleContext } from "../../context/ToggleContext";
import { UserContext } from "../../context/UserContext";
// Images
import CatImage from "../../assets/images/pages/404cat.png";

function Error404() {
  const { setActiveNav } = useContext(ToggleContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setActiveNav("*");
  }, []);

  return (
    <div className="h-screen overflow-hidden grid bg-gray-50 dark:bg-black dark:text-gray-100 font-poppins">
      <section className="grid h-full overflow-hidden grid-rows-reg">
        {user.id ? <Navbar /> : <EmptyNavbar />}

        {/* Main content */}
        <main className="relative h-full w-full">
          <section className="grid absolute w-full text-center h-full items-center justify-center z-10">
            <section className="grid gap-2">
              <article className="outline outline-2 font-semibold outline-black -mt-10 rounded">
                <div className="p-4">
                  <h1 className="text-4xl text-left">
                    ERROR <span className="text-red-500">404</span>
                  </h1>
                  <h2 className="text-4xl">PAGE NOT FOUND</h2>
                </div>
                <article className="text-red-500 text-left pl-4 border-t-2 border-solid border-black p-2">
                  <h3 className="uppercase">But you found a friend 💖</h3>
                </article>
              </article>
            </section>
          </section>
          <section className="flex h-full lg:justify-end lg:mr-20">
            <img src={CatImage} alt="lost cat" />
          </section>
        </main>
      </section>
    </div>
  );
}

export default Error404;
