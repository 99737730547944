import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// Context
import { UserContext } from '../../context/UserContext';
import { ToggleContext } from '../../context/ToggleContext';
// Images
import LogoImage from '../../assets/images/logos/Silhouette Portal Small.png';
import { ACCOUNT_PAGE_URL, ADMIN_PAGE_URL, ADMIN_ROLE, API_PAGE_URL, DEVELOPER_PAGE_URL, DEVELOPER_ROLE, HOME_PAGE_URL, LOGIN_PAGE_URL, REPOS_PAGE_URL, SALES_PAGE_URL } from '../../utils/Constants';

function Navbar() {
  const { user, setUser } = useContext(UserContext);
  const {
    toggleNavbarOpenClosed,
    closeNavbar,
    toggleNavigation,
    activeNav,
    setActiveNav,
  } = useContext(ToggleContext);

  let navigate = useNavigate();

  const logoutUser = (event) => {
    event.preventDefault();
    setActiveNav(HOME_PAGE_URL);
    toggleNavbarOpenClosed();
    setUser({});
    localStorage.removeItem(process.env.REACT_APP_USER_TOKEN);

    navigate(HOME_PAGE_URL, { replace: true });
  };

  return (
    <nav className='h-full relative z-30 grid grid-cols-reg bg-main-colour py-2 shadow-[rgba(13,_38,_76,_0.25)_0px_2px_10px]'>
      <section className='grid items-center justify-center pl-4'>
        <Link className='no__highlights' to={HOME_PAGE_URL}>
          <img
            className='w-12 no__highlights h-12'
            src={LogoImage}
            alt='Logo'
          />
        </Link>
      </section>

      {/* Phone Nav Button*/}
      <nav
        onClick={() => {
          toggleNavbarOpenClosed();
        }}
        className='grid items-center justify-end lg:hidden no__highlights pr-4'
      >
        <span className='cursor-pointer text-white hover:text-hover-grey'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-10 h-10 transition no__highlights duration-200 ease-in-out select-none focus:scale-125 active:scale-125'
            data-te-animation-init
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
            />
          </svg>
        </span>
      </nav>

      {/* Desktop Navigation */}
      <section className='hidden lg:grid justify-end'>
        <div className='grid items-center pr-4'>
          <ul className='grid grid-flow-col w-fit justify-end gap-4 font-semibold'>
            <li
              className={
                activeNav === HOME_PAGE_URL
                  ? 'text-white dark:text-white hover:text-gray-700 active:scale-95'
                  : 'hover:text-gray-700 active:scale-95'
              }
            >
              <Link className='w-full' to={HOME_PAGE_URL}>
                Home
              </Link>
            </li>
            <li
              className={
                activeNav === REPOS_PAGE_URL
                  ? 'text-white dark:text-white hover:text-gray-700 active:scale-95'
                  : 'hover:text-gray-700 active:scale-95'
              }
            >
              <Link className='w-full' to={REPOS_PAGE_URL}>
                Repos
              </Link>
            </li>
            <li
              className={
                activeNav === ACCOUNT_PAGE_URL
                  ? 'text-white dark:text-white hover:text-gray-700 active:scale-95'
                  : 'hover:text-gray-700 active:scale-95'
              }
            >
              <Link className='w-full' to={ACCOUNT_PAGE_URL}>
                Account
              </Link>
            </li>
            <li
              className={
                activeNav === SALES_PAGE_URL
                  ? 'text-white dark:text-white hover:text-gray-700 active:scale-95'
                  : 'hover:text-gray-700 active:scale-95'
              }
            >
              <Link className='w-full' to={SALES_PAGE_URL}>
                Sales
              </Link>
            </li>

            <li
              className={
                activeNav === API_PAGE_URL
                  ? 'text-white dark:text-white hover:text-gray-700 active:scale-95'
                  : 'hover:text-gray-700 active:scale-95'
              }
            >
              <Link className='w-full' to={API_PAGE_URL}>
                API
              </Link>
            </li>

            {/* Protected admin/dev routes */}
            {(user.role === ADMIN_ROLE|| user.role === DEVELOPER_ROLE) && (
              <li
                className={
                  activeNav === ADMIN_PAGE_URL
                    ? 'text-white dark:text-white hover:text-gray-700 active:scale-95'
                    : 'hover:text-gray-700 active:scale-95'
                }
              >
                <Link className='w-full' to={ADMIN_PAGE_URL}>
                  Admin
                </Link>
              </li>
            )}
            {user.role === DEVELOPER_ROLE && (
              <li
                className={
                  activeNav === DEVELOPER_PAGE_URL
                    ? 'text-white dark:text-white hover:text-gray-700 active:scale-95'
                    : 'hover:text-gray-700 active:scale-95'
                }
              >
                <Link className='w-full' to={DEVELOPER_PAGE_URL}>
                  Developer
                </Link>
              </li>
            )}
            {!user.email && (
              <li
                className={
                  activeNav === LOGIN_PAGE_URL
                    ? 'text-white dark:text-white hover:text-gray-700 active:scale-95'
                    : 'hover:text-gray-700 active:scale-95'
                }
              >
                <Link className='w-full' to={LOGIN_PAGE_URL}>
                  Login
                </Link>
              </li>
            )}
            {user.email && (
              <button className='' onClick={logoutUser}>
                Logout
              </button>
            )}
          </ul>
        </div>
      </section>

      {toggleNavigation && (
        <nav className='absolute lg:hidden w-full left-0 top-[100%]'>
          <div className='bg-white nav__bg p-1 rounded'>
            <ul className='text-center grid bg-white h-fit w-full text-xl'>
              <li
                className={
                  activeNav === HOME_PAGE_URL
                    ? 'w-full no__highlights text-white nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-colour-dark font-semibold'
                    : 'w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-main-colour text-gray-800 font-semibold'
                }
              >
                <Link className='w-full' onClick={closeNavbar} to={HOME_PAGE_URL}>
                  Home
                </Link>
              </li>
              <li
                className={
                  activeNav === REPOS_PAGE_URL
                    ? 'w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-colour-dark text-white font-semibold'
                    : 'w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-main-colour text-gray-800 font-semibold'
                }
              >
                <Link className='w-full' onClick={closeNavbar} to={REPOS_PAGE_URL}>
                  Repos
                </Link>
              </li>
              <li
                className={
                  activeNav === ACCOUNT_PAGE_URL
                    ? 'w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-colour-dark text-white font-semibold'
                    : 'w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-main-colour text-gray-800 font-semibold'
                }
              >
                <Link className='w-full' onClick={closeNavbar} to={ACCOUNT_PAGE_URL}>
                  Account
                </Link>
              </li>
              <li
                className={
                  activeNav === SALES_PAGE_URL
                    ? 'text-white dark:text-white hover:text-gray-700 active:scale-95'
                    : 'hover:text-gray-700 active:scale-95'
                }
              >
                <Link className='w-full' onClick={closeNavbar} to={SALES_PAGE_URL}>
                  Sales
                </Link>
              </li>
              <li
                className={
                  activeNav === API_PAGE_URL
                    ? 'text-white dark:text-white hover:text-gray-700 active:scale-95'
                    : 'hover:text-gray-700 active:scale-95'
                }
              >
                <Link className='w-full' onClick={closeNavbar} to={API_PAGE_URL}>
                  API
                </Link>
              </li>
              {!user.email && (
                <li
                  className={
                    activeNav === LOGIN_PAGE_URL
                      ? 'w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-colour-dark text-white font-semibold'
                      : 'w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-main-colour text-gray-800 font-semibold'
                  }
                >
                  <Link className='w-full' to={LOGIN_PAGE_URL}>
                    Login
                  </Link>
                </li>
              )}
              {(user.role === 'ADMIN' || user.role === 'DEVELOPER') && (
                <li
                  className={
                    activeNav === ADMIN_PAGE_URL
                      ? 'w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-colour-dark text-white font-semibold'
                      : 'w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-main-colour text-gray-800 font-semibold'
                  }
                >
                  <Link className='w-full' onClick={closeNavbar} to={ADMIN_PAGE_URL}>
                    Admin
                  </Link>
                </li>
              )}
              {user.role === 'DEVELOPER' && (
                <li
                  className={
                    activeNav === DEVELOPER_PAGE_URL
                      ? 'w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-colour-dark text-white font-semibold'
                      : 'w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-main-colour text-gray-800 font-semibold'
                  }
                >
                  <Link
                    className='w-full'
                    onClick={closeNavbar}
                    to={DEVELOPER_PAGE_URL}
                  >
                    Developer
                  </Link>
                </li>
              )}
              {user.email && (
                <button
                  className='w-full no__highlights nav__bg hover:bg-colour-light active:scale-95 grid py-2 outline-2 outline outline-white bg-main-colour text-gray-800 font-semibold active:text-white'
                  onClick={logoutUser}
                >
                  Logout
                </button>
              )}{' '}
            </ul>
          </div>
        </nav>
      )}
    </nav>
  );
}

export default Navbar;
