import axios from 'axios';

let host = process.env.REACT_APP_API_URL;
let saltPass = process.env.REACT_APP_SALT_PASS;
let tokenKey = process.env.REACT_APP_USER_TOKEN;
let oauth = process.env.REACT_APP_AZURE_APP_OAUTH;
let password = process.env.REACT_APP_AZURE_APP_PASSWORD;

const client = {
  get: (path) => {
    const url = `${host}${path}`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem(tokenKey)}`,
    };

    return axios.get(url, { headers });
  },

  getOutside: (path) => {
    const url = `${path}`;

    const headers = {
      Authorization: `Basic ${oauth}`,
    };

    return axios.get(url, { headers });
  },

  getBasic: (path) => {
    const url = `${host}${path}`;
    return axios.get(url);
  },

  getLittle: (path, headers) => {
    const url = `${path}`;
    return axios.get(url, { headers }); // Pass headers as an object
  },

  secureGet: (path) => {
    const url = `${path}`;
    const authString = `${password}`;
    
    const base64AuthString = btoa(authString); // Use btoa to encode in browser environments
    
    const headers = {
      Authorization: `Basic ${oauth}`,
    };

    return axios.get(url, { headers });
  },

  post: (path, data, withToken = true) => {
    const url = `${host}${path}`;
    const token = localStorage.getItem(tokenKey);
    let headers = {};

    if (withToken) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    return axios.post(url, data, { headers });
  },

  postWithPass: (path, data, withToken = true) => {
    const url = `${host}${path}`;
    const token = localStorage.getItem(tokenKey);
    let headers = {};

    headers['Authorization'] = `${saltPass}`;

    return axios.post(url, data, { headers });
  },

  postPdf: (path, data, withToken = true) => {
    const url = `${host}${path}`;

    const token = localStorage.getItem(tokenKey);
    let headers = {};

    if (withToken) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return axios.post(url, data);
  },

  patch: (path, data, withToken = true) => {
    const url = `${host}${path}`;
    const token = localStorage.getItem(tokenKey);
    let headers = {};

    if (withToken) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    return axios.patch(url, data, { headers });
  },

  delete: (path) => {
    const url = `${host}${path}`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem(tokenKey)}`,
    };

    return axios.delete(url, { headers });
  },
};

export default client;
