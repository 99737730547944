import React, { useContext } from "react";
// Context
import { UserContext } from "../../context/UserContext";

function RoleSelect({ handleRoleChange }) {
  const { user } = useContext(UserContext);

  return (
    <select
      id="role_select"
      name="role_select"
      onChange={handleRoleChange}
      className="outline outline-1 w-full outline-black rounded px-1 py-1"
      required
    >
      <option defaultValue="USER">User</option>
      {user.role !== "DEVELOPER" ? (
        <option disabled value="DEVELOPER">
          Developer
        </option>
      ) : (
        <option value="DEVELOPER">Developer</option>
      )}
      <option value="ADMIN">Admin</option>
      <option value="SALES">Sales</option>
      <option value="QUALITY">Quality</option>
    </select>
  );
}

export default RoleSelect;
