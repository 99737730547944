import React from "react";
import { useEffect, useState } from "react";
// API
import client from "../api/client";
// Utils
import LoggedInUser from "../utils/LoggedInUser";
// Data
import { tempUserData } from "../utils/TempData";
import { useLocation, useNavigate } from 'react-router-dom';

export const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(tempUserData);
  const [userToDelete, setUserToDelete] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const [quickRegisterUserData, setQuickRegisterUserData] = useState({});

  const [token, setToken] = useState(
    localStorage.getItem(process.env.REACT_APP_USER_TOKEN) || ""
  );

  let navigate = useNavigate();
  let location = useLocation()

  const [toggleCookiePolicy, setToggleCookiePolicy] = useState(false);

  const homeLink = () => {
    console.log('GHOM');
      navigate('/home', { replace: true });
  }

  useEffect(() => {
    console.log('aaaa');
    const decodedUserData = LoggedInUser();

    if (decodedUserData) {
      client
        .get(`/users/get-user/${decodedUserData.id}`)
        .then((res) => {
          setUser(res.data.data.user);
          console.log('location', location.pathname);
        })

        .catch((err) => {
          console.error("Unable to retrieve user data", err);
        });
    }

    const cookie = localStorage.getItem("CookiePolicy");

    if (cookie) {
      setToggleCookiePolicy(true);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        toggleCookiePolicy,
        setToggleCookiePolicy,
        // Delete user
        userToDelete,
        setUserToDelete,
        // User to edit
        selectedUser,
        setSelectedUser,
        quickRegisterUserData,
        setQuickRegisterUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
