import React, { useEffect, useState } from 'react';
// Api
import client from '../../api/client';
// Components
import EventItem from './EventItem';
// Constants
import { ALL_EVENTS_API_URL } from '../../utils/Constants';

function EventsOverview() {
  const [allEvents, setAllEvents] = useState([]);

  // Get full list of events from server
  useEffect(() => {
    client
      .get(ALL_EVENTS_API_URL)
      .then((res) => {
        setAllEvents(res.data.data.events);
      })

      .catch((err) => {
        console.error('Unable to retrieve events data', err);
      });
  }, []);

  return (
    <section className='grid grid-rows-reg shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] rounded bg-gray-100 h-full w-full overflow-hidden'>
      <div className='grid grid-cols-events border-b-[1px] border-black border-solid overflow-hidden'>
        <div>
          <span>Type</span>
        </div>
        <div>
          <span>Code</span>
        </div>
        <div>
          <span>Topic</span>
        </div>
        <div>
          <span>Content</span>
        </div>
        <div>
          <span>Created By</span>
        </div>
        <div>
          <span>Received By</span>
        </div>
        <div>
          <span>Viewed</span>
        </div>
        <div>
          <span>Date</span>
        </div>
      </div>

      {/* List of event items */}
      <section className='grid w-full h-full overflow-y-auto'>
        <div>
          {allEvents.map((event, index) => {
            return <EventItem key={index} event={event} />;
          })}
        </div>
      </section>
    </section>
  );
}

export default EventsOverview;
