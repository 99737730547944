import React, { useContext, useEffect, useState } from 'react';
// Api
import client from '../../api/client';
// Context
import { ModalContext } from '../../context/ModalContext';

function LockstepAdminPanel() {
  const {
    setApprovalModalIsOpen,
    lockstepId,
    setLockstepId,
    newLockstepId,
    setNewLockstepId,
  } = useContext(ModalContext);

  useEffect(() => {
    client
      .get(`/version/get-current-version`)
      .then((res) => {
        console.log('res', res.data.data.current);

        setLockstepId({
          major: res.data.data.current.major,
          minor: res.data.data.current.minor,
          revision: res.data.data.current.revision,
        });
      })
      .catch((err) => {
        console.error('Unable to get current lockstep ID', err);
      });
  }, []);

  const updateLockstepConfirm = (event) => {
    event.preventDefault();

    setApprovalModalIsOpen(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewLockstepId((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <section className='grid h-full px-1 py-2'>
      <div className='grid grid-rows-reg overflow-hidden h-full w-full gap-4 p-1'>
        <article className='dark:text-white'>
          <div className='text-xl mb-2'>
            <h3>Lockstep data</h3>
          </div>
          <div>
            <p className='text-xs'>
              You can update and edit the Lockstep ID for all projects via this
              page. The information is stored on this pages server and can only
              be edited here and on the host computer.
            </p>
          </div>
        </article>

        <section className='grid grid-rows-reg gap-4'>
          {/* Current version */}
          <div className='outline outline-1 outline-white px-4 py-4'>
            <section>
              <div className='mb-2'>
                <h4 className='dark:text-black bg-gray-200 px-1 py-1'>
                  Current lockstep version - Major:Minor:Revision
                </h4>
              </div>
              <div className='dark:bg-white bg-gray-200 w-fit px-2'>
                <span>{lockstepId.major}</span>.<span>{lockstepId.minor}</span>.
                <span>{lockstepId.revision}</span>
              </div>
            </section>
          </div>
          {/* Update version */}
          <div className='outline outline-1 outline-gray-300 rounded py-1 px-2'>
            <section className='grid gap-1 max-w-xs'>
              <div className='grid'>
                <label htmlFor='major' className='dark:text-white'>
                  Major
                </label>
                <input
                  type='number'
                  name='major'
                  id='major'
                  value={newLockstepId.major}
                  className='outline outline-1 outline-gray-300 rounded pl-2'
                  onChange={handleInputChange}
                />
              </div>
              <div className='grid'>
                <label htmlFor='minor' className='dark:text-white'>
                  Minor
                </label>
                <input
                  type='number'
                  name='minor'
                  id='minor'
                  value={newLockstepId.minor}
                  className='outline outline-1 outline-gray-300 rounded pl-2'
                  onChange={handleInputChange}
                />
              </div>
              <div className='grid'>
                <label htmlFor='revision' className='dark:text-white'>
                  Revision
                </label>
                <input
                  type='number'
                  name='revision'
                  id='revision'
                  value={newLockstepId.revision}
                  className='outline outline-1 outline-gray-300 rounded pl-2'
                  onChange={handleInputChange}
                />
              </div>
              <section className='mt-2 grid justify-end'>
                <button
                  onClick={(event) => updateLockstepConfirm(event)}
                  className='dark:bg-white bg-main-colour text-white outline outline-1 outline-white dark:outline-main-colour dark:text-black px-2 py-1 rounded'
                >
                  Submit
                </button>
              </section>
            </section>
          </div>
        </section>
      </div>
    </section>
  );
}

export default LockstepAdminPanel;
