import React from 'react';
import { useState } from 'react';

export const ToggleContext = React.createContext();

const ToggleContextProvider = ({ children }) => {
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleDeleteUserModal, setToggleDeleteUserModal] = useState(false);
  const [activeNav, setActiveNav] = useState('/');
  const [pdfPopoutIsOpen, setpdfPopoutIsOpen] = useState(false);
  const [googleDriveFileIsDownloading, setgoogleDriveFileIsDownloading] =
    useState(false);

  console.log('googleDriveFileIsDownloading', googleDriveFileIsDownloading);

  // Azure Devops
  // Devops build list
  const [foundBuildsList, setFoundBuildsList] = useState([]);
  console.log('foundBuildsList', foundBuildsList);

  const [currentPage, setCurrentPage] = useState(1);
  const [displayedBuilds, setDisplayedBuilds] = useState(1);


  // Navigation modals
  const toggleNavbarOpenClosed = () => {
    setToggleNavigation(!toggleNavigation);
  };

  const closeNavbar = () => {
    setToggleNavigation(false);
  };

  // Confirm delete user modals
  const openDeleteUserModal = () => {
    setToggleDeleteUserModal(true);
  };

  const closeDeleteUserModal = () => {
    setToggleDeleteUserModal(false);
  };

  // PDF display
  const openPdfViewer = () => {
    setpdfPopoutIsOpen(true);
  };
  const closePdfViewer = () => {
    setpdfPopoutIsOpen(false);
  };
  return (
    <ToggleContext.Provider
      value={{
        // Navigation
        toggleNavigation,
        toggleNavbarOpenClosed,
        activeNav,
        setActiveNav,
        closeNavbar,
        // Delete user modal
        toggleDeleteUserModal,
        openDeleteUserModal,
        closeDeleteUserModal,
        // pdf
        pdfPopoutIsOpen,
        openPdfViewer,
        closePdfViewer,
        // Download
        googleDriveFileIsDownloading,
        setgoogleDriveFileIsDownloading,
        foundBuildsList,
        setFoundBuildsList,
        currentPage,
        setCurrentPage,
        displayedBuilds,
        setDisplayedBuilds,
      }}
    >
      {children}
    </ToggleContext.Provider>
  );
};

export default ToggleContextProvider;
