import React, { useContext, useEffect, useState } from 'react';
// Components
import Navbar from '../../components/nav/Navbar';
import EventsOverview from '../../components/developer/EventsOverview';
// Context
import { ToggleContext } from '../../context/ToggleContext';
// Constants
import { DEVELOPER_PAGE_URL, EVENTS_DEV_TAB_PATH } from '../../utils/Constants';

function DeveloperPage() {
  const { setActiveNav } = useContext(ToggleContext);

  const [activeTab, setActiveTab] = useState(EVENTS_DEV_TAB_PATH);

  useEffect(() => {
    setActiveNav(DEVELOPER_PAGE_URL);
  }, []);

  const selectEventsTab = () => {
    setActiveTab(EVENTS_DEV_TAB_PATH);
  };

  return (
    <div className='grid font-poppins h-screen grid-rows-reg max-h-screen overflow-hidden dark:bg-dark-main'>
      <Navbar />

      {/* Main content */}
      <main className='grid h-full w-full p-4 overflow-hidden'>
        <div className='grid grid-rows-reg h-full w-full p-2 overflow-hidden'>
          <article>
            <h3 className='text-2xl dark:text-white'>Developer Panel</h3>
          </article>

          <section className='relative grid grid-rows-reg h-full w-full overflow-hidden p-1'>
            {/* Tabs */}
            <div className='grid grid-cols-4 w-fit pl-2 text-white'>
              <div
                onClick={selectEventsTab}
                className={
                  activeTab === 'overview-tab'
                    ? 'grid px-2 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg bg-colour-dark cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                    : 'grid px-2 lg:px-8 py-2 w-full h-[40px] rounded-tl-lg rounded-tr-lg bg-main-colour  cursor-pointer active:scale-95 hover:translate-y-[-5px]'
                }
              >
                <span className='text-white text-center'>Events</span>
              </div>
            </div>

            {/* Main Content */}
            <section className='grid h-full w-full shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] dark:shadow-[rgba(255,_255,_255,_0.25)_0px_2px_5px] rounded p-2 overflow-hidden bg-gray-50'>
              {activeTab === 'events-tab' && <EventsOverview />}
            </section>
          </section>
        </div>
      </main>
    </div>
  );
}

export default DeveloperPage;
