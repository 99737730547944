import React from 'react';
// Icon
import { ImBin } from 'react-icons/im';

function NewUserRequest({
  request,
  createNewUserFromRequest,
  index,
  deleteUserRequest,
}) {
  return (
    <div className='grid grid-cols-rev'>
      <article
        onClick={() => createNewUserFromRequest(request)}
        title='Click the request to quick register user.'
        className={
          index % 2 === 0
            ? 'bg-main-colour grid grid-cols-foura h-fit py-1 px-1 cursor-pointer hover:brightness-105'
            : 'bg-colour-light grid grid-cols-foura h-fit py-1 px-1 cursor-pointer hover:brightness-105'
        }
      >
        <div>{request.firstName}</div>
        <div>{request.lastName}</div>
        <div>{request.email}</div>
        <div>{request.country}</div>
      </article>
      <div
        title='Delete user request'
        onClick={() => deleteUserRequest(request)}
        className={
          index % 2 === 0
            ? 'bg-main-colour grid cursor-pointer hover:brightness-105 items-center px-2'
            : 'bg-colour-light grid cursor-pointer hover:brightness-105 items-center px-2'
        }
      >
        <ImBin />
      </div>
    </div>
  );
}

export default NewUserRequest;
