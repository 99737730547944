import React from 'react';

function EventItem({ event }) {
  return (
    <article className='grid grid-cols-events bg-gray-50 border-b-2 border-solid border-gray-200 text-xs p-1 h-fit'>
      <div>
        <span>{event.type}</span>
      </div>
      <div className='grid'>
        {event.code === 500 && (
          <h3 className='text-red-600 font-semibold'>{event.code}</h3>
        )}
        {(event.code === 400 || event.code === 401 || event.code === 404) && (
          <h3 className='text-orange-600 font-semibold'>{event.code}</h3>
        )}
        {(event.code === 200 || event.code === 201) && (
          <h3 className='text-green-600 font-semibold'>{event.code}</h3>
        )}
      </div>
      <div>
        <span>{event.topic}</span>
      </div>
      <div className='whitespace-nowrap overflow-clip'>
        <span>{event.content}</span>
      </div>
      <div className='whitespace-nowrap overflow-clip'>
        <span>{event.createdById}</span>
      </div>
      <div className='whitespace-nowrap overflow-clip'>
        <span>{event.receivedById}</span>
      </div>
      <div>
        <span>{String(event.viewed)}</span>
      </div>
      <div>
        <span>{event.createdAt}</span>
      </div>
    </article>
  );
}

export default EventItem;
