import React, { useContext, useEffect, useState } from 'react';
// Context
import { ToggleContext } from '../../context/ToggleContext';
// Constants
import { API_PAGE_URL, GET_API_DOC_API_URL, UPDATE_API_DOC_API_URL } from '../../utils/Constants';
// Components
import Navbar from '../../components/nav/Navbar';
import YamlApiEditor from '../../components/api/YamlApiEditor';
// Swagger
import SwaggerApiComponent from '../../components/api/SwaggerApiComponent';
// Api
import client from '../../api/client';
// Yaml
import yaml from 'js-yaml';
import ConfirmYamlUpdateModal from '../../components/modals/ConfirmYamlUpdateModal';

function ApiPage() {
  const { setActiveNav } = useContext(ToggleContext);
  const [yamlContent, setYamlContent] = useState('');
  const [editingApi, setEditingApi] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [isValidYaml, setIsValidYaml] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    setActiveNav(API_PAGE_URL);
  }, []);

  useEffect(() => {
    // Fetch the YAML content from a file or API
    client
      .get(GET_API_DOC_API_URL)
      .then((res) => {
        setYamlContent(res.data.data.api);
        setEditorContent(res.data.data.api);
      })

      .catch((err) => {
        console.error('Unable to get api diagram', err);
      });
  }, []);

  const handleEditorChange = (editor, data, value) => {
    setEditorContent(value);
    try {
      yaml.load(value); // Validate YAML content
      setIsValidYaml(true);
    } catch (e) {
      setIsValidYaml(false);
    }
  };

  const updateSwaggerUI = () => {
    console.log('1111111111');
    if (isValidYaml) {
      setYamlContent(editorContent);
      console.log('222222222222222');

      client
        .patch(UPDATE_API_DOC_API_URL, editorContent, false)
        .then((res) => {
          console.log(res.data.data.updateApi);
          setYamlContent(res.data.data.updateApi);
          setEditorContent(res.data.data.updateApi);
        })

        .catch((err) => {
          console.error('Unable to update api diagram', err);
        });
    } else {
      alert('Invalid YAML content. Please fix the errors.');
    }
  };

  const toggleYamlEditor = () => {
    setEditingApi(!editingApi);
  };

  const confirmUpdateSwaggerUI = () => {
    console.log('xxxxxxxxxx');
    setConfirmModalOpen(true);
  };

  const closeModal = () => {
    setConfirmModalOpen(false);
  };

  return (
    <div className='relative grid font-poppins h-screen grid-rows-reg overflow-hidden max-h-screen bg-white dark:bg-dark-main '>
      <Navbar />

      {/* Main content */}
      <main className='relative grid grid-rows-reg h-full w-full px-4 overflow-y-auto'>
        {/* Edit button */}
        <section className='grid justify-end pt-4 items-center h-fit'>
          <button
            onClick={toggleYamlEditor}
            className='px-2 py-1 text-lg bg-main-colour rounded shadow-lg text-white hover:brightness-90 active:scale-95'
          >
            {editingApi ? 'View YAML' : 'Edit YAML'}
          </button>
        </section>

        <div className='bg-blue-50 container mx-auto h-full w-full mt-4'>
          {editingApi ? (
            <YamlApiEditor
              handleEditorChange={handleEditorChange}
              editorContent={editorContent}
              confirmUpdateSwaggerUI={confirmUpdateSwaggerUI}
            />
          ) : (
            <SwaggerApiComponent yamlContent={yamlContent} />
          )}
        </div>

        {!isValidYaml && (
          <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <div className='text-red-500 text-3xl text-center'>
              <p>WARNING!</p>
              <p>Invalid YAML content</p>
            </div>
          </div>
        )}
      </main>

      {/* Confirm modal */}
      {confirmModalOpen && (
        <ConfirmYamlUpdateModal
          closeModal={closeModal}
          updateSwaggerUI={updateSwaggerUI}
        />
      )}
    </div>
  );
}

export default ApiPage;
