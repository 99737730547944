import React from 'react';
// Swagger
import 'swagger-ui-react/swagger-ui.css';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';

function YamlApiEditor({
  handleEditorChange,
  confirmUpdateSwaggerUI,
  editorContent,
}) {
  return (
    <div className='grid h-full'>
      <CodeMirror
        value={editorContent}
        options={{
          mode: 'yaml',
          theme: 'default',
          lineNumbers: true,
        }}
        onBeforeChange={(editor, data, value) => {
          handleEditorChange(editor, data, value);
        }}
      />

      <section className='fixed bottom-0'>
        <div className='grid py-4 px-4 h-fit'>
          <button
            onClick={confirmUpdateSwaggerUI}
            className='px-2 py-1 bg-main-colour rounded shadow-lg text-white hover:brightness-110 active:scale-95'
          >
            Update Swagger UI
          </button>
        </div>
      </section>
    </div>
  );
}

export default YamlApiEditor;
