import React from 'react';
import { useState } from 'react';

export const PdfContext = React.createContext();

const PdfContextProvider = ({ children }) => {
  const [webPDFs, setWebPDFs] = useState([]);
  const [connectPDFs, setConnectPDFs] = useState([]);
  const [desktopPDFs, setDesktopPDFs] = useState([]);
  const [mobilePDFs, setMobilePDFs] = useState([]);

  const [pdfUrl, setPdfUrl] = useState('');

  return (
    <PdfContext.Provider
      value={{
        webPDFs,
        setWebPDFs,
        connectPDFs,
        setConnectPDFs,
        desktopPDFs,
        setDesktopPDFs,
        mobilePDFs,
        setMobilePDFs,
        pdfUrl,
        setPdfUrl,
      }}
    >
      {children}
    </PdfContext.Provider>
  );
};

export default PdfContextProvider;
