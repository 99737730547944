import React from 'react';

function ChangesContainer() {
  return (
    <section className='grid grid-rows-reg'>
      <div className='text-center text-xl py-4 dark:text-white'>
        <h4>
          Build Changes
        </h4>
      </div>

      {/* Changes data */}
      <section className='mt-4 p-2 dark:text-white'>
        <div>Changes Container</div>
        <div className='py-2 pr-4'>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim
            dolorem id praesentium at, consectetur quos accusantium, molestias
            pariatur ipsa sunt vero, quibusdam quaerat amet consequatur
            architecto exercitationem beatae alias asperiores aliquid ratione
            officiis dignissimos atque!
          </p>
        </div>
        <div className='py-2 pr-4'>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Praesentium doloribus voluptatem impedit molestiae odio tempore
            commodi maiores, maxime officiis ullam velit vero deserunt
            necessitatibus veritatis?
          </p>
        </div>
        <div className='py-2 pr-4'>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Praesentium doloribus voluptatem impedit molestiae odio tempore
            commodi maiores, maxime officiis ullam velit vero deserunt
            necessitatibus veritatis?
          </p>
        </div>
        <div className='py-2 pr-4'>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Praesentium doloribus voluptatem impedit molestiae odio tempore
            commodi maiores, maxime officiis ullam velit vero deserunt
            necessitatibus veritatis?
          </p>
        </div>
      </section>
    </section>
  );
}

export default ChangesContainer;
