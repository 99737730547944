import React, { useState } from 'react';
// Utils
import CountrySelect from '../utils/CountrySelect';
// Components
import LoadingSpinner from '../utils/LoadingSpinner';
// Api
import client from '../../api/client';

function RequestAccessForm() {
  const [newUserData, setNewUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    country: 'united kingdom',
  });
  const [newUserFormStatus, setNewUserFormStatus] = useState({
    active: false,
    success: false,
  });
  const [successfulRequest, setSuccessfulRequest] = useState(false);

  // Listen for user data changes
  const handleChange = (event) => {
    const { name, value } = event.target;

    setNewUserData({
      ...newUserData,
      [name]: value,
    });
  };

  // Listen for country changes
  const handleCountryChange = (event) => {
    const { value } = event.target;

    setNewUserData({
      ...newUserData,
      country: value,
    });
  };

  // Submit request
  const handleSubmitUserRequest = (event) => {
    event.preventDefault();

    setNewUserFormStatus({
      ...newUserFormStatus,
      active: true,
    });

    client
      .post('/users/request-access', newUserData, false)

      .then((res) => {
        console.log('res', res.data);
        setNewUserFormStatus({
          ...newUserFormStatus,
          active: false,
          success: true,
        });
        setSuccessfulRequest(true);
      })

      .catch((err) => {
        console.error('Unable to login', err);
        setNewUserFormStatus({
          ...newUserFormStatus,
          active: false,
        });
      });
  };

  return (
    <section className='w-full'>
      <form onSubmit={handleSubmitUserRequest}>
        {!successfulRequest ? (
          <div>
            <div className='mb-2'>
              <label>First Name:</label>
              <input
                type='text'
                id='firstName'
                name='firstName'
                className='form-control block w-full px-3 py-1.5 text-base font-normal min-w-[240px] text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                placeholder=''
                onChange={handleChange}
              />
            </div>
            <div className='mb-2'>
              <label>Last Name:</label>
              <input
                type='text'
                id='lastName'
                name='lastName'
                className='form-control block w-full px-3 py-1.5 text-base font-normal min-w-[240px] text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                onChange={handleChange}
              />
            </div>
            <div className='mb-2'>
              <label>Email:</label>
              <input
                type='email'
                id='email'
                name='email'
                className='form-control block w-full px-3 py-1.5 text-base font-normal min-w-[240px] text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                onChange={handleChange}
                autoComplete='new-password'
              />
            </div>

            {/* Country select drop down */}
            <div>
              <div className='mb-2 grid'>
                <label className='pr-2'>Country: </label>
                <CountrySelect handleCountryChange={handleCountryChange} />
              </div>
            </div>

            {/* Submit */}
            <div className='mt-6'>
              <button
                type='submit'
                data-mdb-ripple='true'
                data-mdb-ripple-color='light'
                className='inline-block px-6 py-2.5 no__highlights mb-6 w-full bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-800 hover:shadow-lg focus:bg-blue-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-900 active:shadow-lg transition duration-150 ease-in-out'
              >
                {!newUserFormStatus.active && !newUserFormStatus.success && (
                  <span>Request Account</span>
                )}
                {newUserFormStatus.active && (
                  <span className='flex items-center justify-center'>
                    <LoadingSpinner width={'w-5'} height={'h-5'} />
                  </span>
                )}
                {newUserFormStatus.success && <span>Success!</span>}
              </button>
            </div>
          </div>
        ) : (
          // Completed Message
          <div className='text-center'>
            <p>Your request has been sent</p>
            <p>You will be granted access shortly</p>
          </div>
        )}
      </form>
    </section>
  );
}

export default RequestAccessForm;
