import React from 'react';
import { useState } from 'react';

export const ModalContext = React.createContext();

const ModalContextProvider = ({ children }) => {
  const [approvalModalIsOpen, setApprovalModalIsOpen] = useState(false);
  
  const [lockstepId, setLockstepId] = useState({
    major: 111,
    minor: 222,
    revision: 333,
  });

  const [newLockstepId, setNewLockstepId] = useState({
    major: '',
    minor: '',
    revision: '',
  });

  return (
    <ModalContext.Provider
      value={{
        approvalModalIsOpen,
        setApprovalModalIsOpen,
        lockstepId,
        setLockstepId,
        newLockstepId,
        setNewLockstepId,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
