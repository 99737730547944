import React, { useContext, useEffect } from 'react';
// Api
import client from '../../api/client';
// Context
import { PdfContext } from '../../context/PdfContext';
// Data
import { AvailableProjectsToView } from '../../utils/data/RepoDataArray';

function SpecDataContainer({ selectSpecToView }) {
  const { setWebPDFs, setConnectPDFs, setDesktopPDFs, setMobilePDFs } =
    useContext(PdfContext);

console.log('XXXXXXXXXXXXXXXXXXXXXXX');
  useEffect(() => {
    // Get full list of available PDFs
    client
      .get(`/pdf/get-all-pdfs`)
      .then((res) => {
        setWebPDFs(res.data.data.webFiles);
        console.log('res.data.data.webFiles', res.data.data.webFiles);
        setConnectPDFs(res.data.data.connectFiles);
        setDesktopPDFs(res.data.data.desktopFiles);
        setMobilePDFs(res.data.data.mobileFiles);
      })
      .catch((err) => {
        console.error('Unable to get PDFs', err);
      });
  }, []);

  return (
    <section className='grid grid-rows-reg'>
      <section>
        <article className='mt-4 mb-8'>
          <div className='text-center'>
            <p className='text-lg dark:text-white'>
              Select from below a project to view a PDF document of
              specifications
            </p>
          </div>
        </article>
      </section>

      {/* Project List - selection buttons */}
      <section className='grid h-fit w-full'>
        <article>
          <h4 className='text-center dark:text-white mb-4'>Silhouette Project Specs</h4>
        </article>
        <div className='grid mx-auto gap-2'>
          {AvailableProjectsToView.map((item, index) => {
            return (
              <div key={index} className=''>
                <button
                  onClick={() => selectSpecToView(item)}
                  className='shadow-[rgba(13,_38,_76,_0.25)_0px_2px_5px] no__highlights bg-main-colour text-white hover:bg-colour-dark active:scale-95 w-full min-w-[260px] h-[50px] text-2xl py-1 px-10 rounded-lg'
                >
                  {item.name}
                </button>
              </div>
            );
          })}
        </div>
      </section>
    </section>
  );
}

export default SpecDataContainer;
